import React, { memo } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import { TableCellComponent } from "./TableCellComponent";

export const TableRowComponent = memo(
  ({
    row,
    columns,
    handleInputChange,
    handleDelete,
    globalTimezone,
    allowBulkEdit,
    selected,
    onRowSelect,
    onBulkEdit,
  }) => {
    return (
      <TableRow selected={selected}>
        {allowBulkEdit && (
          <TableCell
            sx={{
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              maxWidth: "48px !important",
              textAlign: "center",
            }}
          >
            <Checkbox
              checked={selected}
              onChange={(e) => onRowSelect(row.id, e.target.checked)}
            />
          </TableCell>
        )}
        {columns.map((column) => {
          if (column.hide && column.hide(row)) {
            return null;
          }
          return (
            <TableCellComponent
              key={column.field}
              column={column}
              row={row}
              handleInputChange={handleInputChange}
              globalTimezone={globalTimezone}
              allowBulkEdit={allowBulkEdit}
              onBulkEdit={onBulkEdit}
              selected={selected}
            />
          );
        })}
        {handleDelete && (
          <TableCell
            sx={{
              position: "sticky",
              right: 0,
              background: "white",
              zIndex: 1,
              minWidth: 50,
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
              textAlign: "center",
            }}
          >
            <IconButton onClick={() => handleDelete(row.id)} size="small">
              <DeleteIcon color="primary" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.row) === JSON.stringify(nextProps.row) &&
      prevProps.globalTimezone === nextProps.globalTimezone &&
      prevProps.selected === nextProps.selected &&
      prevProps.allowBulkEdit === nextProps.allowBulkEdit
    );
  }
);
