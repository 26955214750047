import { performInventoryAction } from "../../../api/InventoryOrders";

export const submitActions = {
  newInventoryOrder: (state) => {
    console.log("Submitting new inventory order");
    const payload = {
      action: "NEW_INVENTORY_ORDER",
      details: {
        dateOrdered: new Date(),
        warehouse: state.warehouse,
        supplier: state.supplier,
        orderNotes: state.notes,
        items: state.rows,
      },
    };
    return performInventoryAction(payload);
  },

  warehouseTransfer: (state) => {
    console.log("Submitting warehouse transfer");
    const payload = {
      action: "STOCK_TRANSFER",
      details: {
        dateOrdered: state.dateOrdered,
        warehouse: state.warehouse,
        supplier: state.supplier,
        orderNotes: state.notes,
        items: state.rows.map((row) => ({
          ...row,
          quantityOrdered:
            parseInt(row.quantityOrdered) < 0
              ? 0
              : parseInt(row.quantityOrdered),
        })),
      },
    };
    return performInventoryAction(payload);
  },

  createAuditCorrection: (state) => {
    const payload = {
      action: "AUDIT_CORRECTION",
      details: {
        dateOrdered: state.dateOrdered,
        warehouse: state.warehouse,
        orderNotes: state.notes,
        items: state.rows.map((row) => ({
          ...row,
          quantityOrdered: parseInt(row.quantityOrdered),
        })),
      },
    };
    console.log("Submitting audit correction", payload);

    return performInventoryAction(payload);
  },

  editInventoryOrder: (state) => {
    console.log("Submitting edited inventory order");
    const payload = {
      action: "EDIT_INVENTORY_ORDER",
      details: state.rows.map((row) => ({
        rowNumber: row.rownumber,
        warehouseOrderNumber: row.warehouseordernumber,
        deleteRow: row.deleteRow || false,
        controlBoardPanelModel: row.controlboardpanelmodel,
        serialNumbers: row.serialnumbers,
        dateOrdered: row.dateordered,
        inventoryItem: row.inventoryitem,
        notes: row.notes,
        pid: row.pid,
        quantityOrdered: parseInt(row.quantityordered),
        supplier: row.supplier,
        tradeFinanceOrder: row.tradefinanceorder,
      })),
    };
    return performInventoryAction(payload);
  },

  shipInventory: (state) => {
    console.log("Submitting ship inventory");
    const details = state.rows[0];
    const payload = {
      action: "SHIP_INVENTORY",
      details: {
        warehouseOrderNumber: details.warehouseordernumber,
        dateProductionComplete: details.dateproductioncomplete,
        dateShipped: details.dateshipped,
        estPortArrivalDate: details.estportarrivaldate,
        vesselName: details.vesselname,
        trackingNumber: details.trackingnumber,
      },
    };
    return performInventoryAction(payload);
  },

  receiveInventory: (state) => {
    console.log("Submitting receive inventory");
    const payload = {
      action: "RECEIVE_INVENTORY",
      details: state.rows.map((row) => ({
        warehouseOrderNumber: row.warehouseordernumber,
        rowNumber: row.rownumber,
        dateInventoryReceived: row.dateinventoryreceived,
        inventoryReceived: row.inventoryreceived,
        quantityReceived:
          parseInt(row.quantityreceived) < 0
            ? 0
            : parseInt(row.quantityreceived),
        notes: row.notes,
      })),
    };
    return performInventoryAction(payload);
  },

  updateStatus: (state) => {
    const row = state.rows[0];
    const status = row.status;

    const details = {
      warehouseOrderNumber: row.warehouseordernumber,
      status: status,
    };

    // These statuses should include shipping-related dates
    const statusesWithDates = [
      "ON SHIP",
      "IN CUSTOMS",
      "INTERNAL TRANSIT",
      "ARRIVED AT WAREHOUSE",
      "RECEIVED",
    ];

    // If status requires shipping dates, include them
    if (statusesWithDates.includes(status)) {
      details.dateShipped = row.dateshipped;
      details.dateProductionComplete = row.dateproductioncomplete;
      details.estPortArrivalDate = row.estportarrivaldate;
    }

    // Add received date only for RECEIVED status
    if (status === "RECEIVED") {
      details.dateInventoryReceived = row.dateinventoryreceived;
    }

    const payload = {
      action: "UPDATE_STATUS",
      details: details,
    };

    return performInventoryAction(payload);
  },

  addCostsByItem: (state) => {
    console.log("Submitting costs by item");
    const payload = {
      action: "ADD_COSTS_BY_ITEM",
      details: state.rows.map((row) => ({
        warehouseOrderNumber: row.warehouseordernumber,
        rowNumber: row.rownumber,
        customsCurrency: row.customscurrency,
        customsFees:
          parseFloat(row.customsfees) < 0 ? 0 : parseFloat(row.customsfees),
        customsDatePaid: row.customsdatepaid,
        currencyIntShipping: row.currencyintshipping,
        internationalShipmentCost:
          parseFloat(row.internationalshipmentcost) < 0
            ? 0
            : parseFloat(row.internationalshipmentcost),
        intShipDatePaid: row.intshipdatepaid,
        localShipmentCost:
          parseFloat(row.localshipmentcost) < 0
            ? 0
            : parseFloat(row.localshipmentcost),
        locShipDatePaid: row.locshipdatepaid,
        currencyLocalShipping: row.currencylocalshipping,
        currencyProduct: row.currencyproduct,
        totalProductCost:
          parseFloat(row.totalproductcost) < 0
            ? 0
            : parseFloat(row.totalproductcost),
        totalShippingCostUSD:
          parseFloat(row.totalshippingcostusd) < 0
            ? 0
            : parseFloat(row.totalshippingcostusd),
      })),
    };
    return performInventoryAction(payload);
  },

  distributeOrderCosts: (state) => {
    console.log("Submitting order cost distribution");
    const row = state.rows[0];
    const payload = {
      action: "DISTRIBUTE_ORDER_COSTS",
      details: {
        warehouseOrderNumber: row.warehouseordernumber,
        customsCurrency: row.customscurrency,
        customsFees:
          parseFloat(row.customsfees) < 0 ? 0 : parseFloat(row.customsfees),
        customsDatePaid: row.customsdatepaid,
        currencyIntShipping: row.currencyintshipping,
        internationalShipmentCost:
          parseFloat(row.internationalshipmentcost) < 0
            ? 0
            : parseFloat(row.internationalshipmentcost),
        intShipDatePaid: row.intshipdatepaid,
        localShipmentCost:
          parseFloat(row.localshipmentcost) < 0
            ? 0
            : parseFloat(row.localshipmentcost),
        locShipDatePaid: row.locshipdatepaid,
        currencyLocalShipping: row.currencylocalshipping,
      },
    };
    return performInventoryAction(payload);
  },

  markAsPaid: (state) => {
    console.log("Submitting mark as paid", state.rows);
    const payload = {
      action: "MARK_AS_PAID",
      details: state.rows.map((row) => ({
        warehouseOrderNumber: row.warehouseordernumber,
        rowNumber: row.rownumber,
        paid: row.paid,
        datePaid: row.datepaid,
      })),
    };
    return performInventoryAction(payload);
  },

  addMachineSpecs: (state) => {
    console.log("Submitting machine specs");
    const payload = {
      action: "ADD_MACHINE_SPECS",
      details: state.rows.map((row) => ({
        warehouseOrderNumber: row.warehouseordernumber,
        rowNumber: row.rownumber,
        controlBoardPanelModel: row.controlboardpanelmodel,
        serialNumbers: row.serialnumbers,
      })),
    };
    return performInventoryAction(payload);
  },

  addNotes: (state) => {
    const payload = {
      action: "ADD_NOTES",
      details: {
        ...state,
      },
    };
    return performInventoryAction(payload);
  },
};
