import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useRef } from "react";
import { deviceType, primaryInput } from "../../utils/inputMethodDetectIt"

export const DataGridCell = (props) => {
  const StyledGridCell = styled(Box)(() => ({
    "&.MuiBox-root": {
      background: props.columnColour,
      display: props.verticalCellAlignment ? "flex" : "",
      width: "100%",
      height: "100%",
      userSelect: "text", // Enable text selection
      WebkitUserSelect: "text", // For iOS Safari
      MozUserSelect: "text", // For Firefox
      msUserSelect: "text", // For IE/Edge
      textAlign: props.verticalCellAlignment,
      alignItems: props.horizontalCellAlignment,
      touchAction: "manipulation", // Better touch handling
      "-webkit-touch-callout": "default", // Enable callout on iOS
    },
    ".MuiTypography-root": {
      fontSize: 12,
      width: "100%",
      padding: "3px",
      textDecoration: props.textDecoration,
      fontWeight: props.fontWeight,
    },
    ".hover-pointer:hover": { cursor: "pointer" },
  }));

  const selectedTextRef = useRef("");

  const handleTouchStart = (event) => {
    const selection = window.getSelection();
    if (!selection.isCollapsed) {
      event.stopPropagation();
      event.preventDefault();
      selectedTextRef.current = selection.toString();
    }
  };

  const handleTouchEnd = (event) => {
    const selection = window.getSelection();
    if (!selection.isCollapsed) {
      event.stopPropagation();
      event.preventDefault();
      selectedTextRef.current = selection.toString();
    }
  };

  const handleMouseUp = (event) => {
    const selection = window.getSelection();
    if (!selection.isCollapsed) {
      event.stopPropagation();
      event.preventDefault();
      selectedTextRef.current = selection.toString();
    }
  };

  const handleMouseDown = (event) => {
    const target = event.target;
    if (target.closest("button") || target.closest("a")) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleCopy = (event) => {
    console.log(`handleCopy - deviceType: ${deviceType}, primaryInput: ${primaryInput}`)
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard
      .writeText(selectedTextRef.current)
      .then(() =>
        console.log("Text copied successfully!", selectedTextRef.current)
      )
      .catch((err) => console.error("Failed to copy text:", err));
  };

  return (
    <StyledGridCell
      onCopy={handleCopy}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {props.children}
    </StyledGridCell>
  );
};
