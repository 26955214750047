import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { cardViewSettingsState } from "../../atoms/atoms";
import { Box, Paper, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDateFormat } from "../../contexts/DateFormatContext";
import { renderCellComponent } from "../Datagrid/utils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function getStyle({ draggableStyle, virtualStyle, isDragging, background }) {
  const combined = {
    background: background,
    ...virtualStyle,
    ...draggableStyle,
  };

  const grid = 8;

  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging
      ? draggableStyle.width
      : `calc(${combined.width} - ${grid * 2}px)`,
    marginBottom: grid,
    marginLeft: 8,
    marginRight: 8,
  };

  return result;
}

export function Item({ table, provided, item, style, isDragging }) {
  const { dateFormat } = useDateFormat();
  const settings = useRecoilValue(cardViewSettingsState);
  const navigate = useNavigate();

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    if (window.getSelection().toString()) {
      return;
    } else {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null
      );
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const openInNewTab = () => {
    let url;
    if (table.toLowerCase() === "orderunit" && item?.wixordernumber) {
      url = `/order/${item.wixordernumber}`;
    } else if (
      table.toLowerCase() === "inventoryorders" &&
      item?.warehouseordernumber
    ) {
      url = `/inventoryOrder/${item.warehouseordernumber}`;
    }

    if (url) {
      window.open(url, "_blank");
    }
    handleClose();
  };

  const onCardClick = (event, card) => {
    if (window.getSelection().toString() || contextMenu) {
      return;
    }
    if (
      !event.target?.classList.contains("card-text") ||
      event.target?.tagName === "IMG"
    ) {
      let url;
      if (table.toLowerCase() === "orderunit" && card?.wixordernumber) {
        url = `/order/${card.wixordernumber}`;
      } else if (
        table.toLowerCase() === "inventoryorders" &&
        card?.warehouseordernumber
      ) {
        url = `/inventoryOrder/${card.warehouseordernumber}`;
      }

      if (url) {
        navigate(url);
      } else if (!table) {
        console.warn(
          "onCardClick: clicked whitespace, header or image, but no table: " +
            table
        );
      }
    }
  };

  return (
    <Paper
      {...provided.draggableProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging,
        background:
          item["orderpaymentstatus"] === "NOT_PAID" || item["canceled"] === true
            ? "#DCDCDC"
            : "white",
      })}
      onClick={(e) => onCardClick(e, item)}
      onContextMenu={handleContextMenu}
      sx={{
        padding: 2,
        boxShadow: 3,
        mt: 1,
        backgroundColor: "background.paper",
        borderRadius: 2,
        position: "relative",
        minHeight: "55px",
        "&:hover": {
          background: "#f9f9f9",
        },
      }}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      {settings.canMoveCards && (
        <Box
          {...provided.dragHandleProps}
          sx={{ position: "absolute", top: 12, right: 12, cursor: "grab" }}
        >
          <MenuIcon />
        </Box>
      )}
      <Box sx={{ paddingRight: "28px" }}>
        {settings?.columnsDisplayed?.map((searchField) => {
          return (
            <Box
              key={searchField.fieldName}
              sx={{ mb: 1 }}
              className={"card-field-container"}
            >
              {settings.showColumnHeaders && (
                <Typography
                  fontSize={14}
                  sx={{
                    color: "#9E9E9E",
                    mb: "2px",
                  }}
                  className={"card-field-header"}
                >
                  {searchField.label || searchField.displayName}:
                </Typography>
              )}

              <Box
                onClick={onCardClick}
                sx={{
                  "& #card-text": {
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    lineHeight: 1.4,
                  },
                }}
                className={"card-field-value"}
              >
                {renderCellComponent(
                  searchField,
                  searchField.fieldName,
                  { value: item[searchField.fieldName], row: item },
                  () => {},
                  dateFormat,
                  false
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={openInNewTab}>Open in New Tab</MenuItem>
      </Menu>
    </Paper>
  );
}
