import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import Table from "../../../components/Table";

const AddCostsByItem = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders.map((row) => ({
        ...row,
        currencyproduct: row.currencyproduct || "USD",
      })),
    });
  }, [data.orders, setState]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        ),
      }));
    },
    [setState]
  );

  const columns = [
    {
      field: "warehouseordernumber",
      headerName: "Warehouse Order Number",
      width: 230,
    },
    {
      field: "rownumber",
      headerName: "Row Number",
      width: 150,
    },
    {
      field: "customscurrency",
      headerName: "Customs Currency",
      width: 180,
      editable: true,
      type: "currency",
    },
    {
      field: "customsfees",
      headerName: "Customs Fees",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "customsdatepaid",
      headerName: "Customs Date Paid",
      width: 250,
      editable: true,
      type: "date",
    },
    {
      field: "currencyintshipping",
      headerName: "Int. Shipping Currency",
      width: 180,
      editable: true,
      type: "currency",
    },
    {
      field: "internationalshipmentcost",
      headerName: "Int. Shipment Cost",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "intshipdatepaid",
      headerName: "Int. Shipping Date Paid",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "localshipmentcost",
      headerName: "Local Shipment Cost",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "locshipdatepaid",
      headerName: "Local Shipping Date Paid",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "currencylocalshipping",
      headerName: "Local Shipping Currency",
      width: 180,
      editable: true,
      type: "currency",
    },
    {
      field: "currencyproduct",
      headerName: "Product Currency",
      width: 180,
      editable: true,
      type: "currency",
    },
    {
      field: "totalproductcost",
      headerName: "Total Product Cost",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "totalshippingcostusd",
      headerName: "Total Shipping Cost (USD)",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="body1" color="text.secondary">
        Add costs for each inventory item.
      </Typography>

      <Table
        columns={columns}
        rows={state.rows}
        onCellChange={handleInputChange}
        maxHeight={700}
        allowBulkEdit
        globalTimezone={data.orders[0].warehouse.slice(0, 2)}
      />
    </Box>
  );
};

export default AddCostsByItem;
