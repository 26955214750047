import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import Divider from "@mui/material/Divider";
import Error from "./Error";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const Modal = ({
  title,
  openModal,
  handleCloseModal,
  children,
  small,
  handleSubmit,
  loading,
  errorMsg,
  large,
  actions,
  maxWidth,
  submitButtonText,
}) => {
  const handleOnKeyUp = (e) => {
    e.preventDefault();
    const ENTER = 13;

    if (e.keyCode === ENTER) {
      if (handleSubmit) handleSubmit();
    }
  };

  const styles = {
    overflowX: "hidden",
  };

  if (small) {
    styles.padding = "10px 24px";
  }

  return (
    <Dialog
      maxWidth={maxWidth || (large ? "md" : small ? "xs" : "sm")}
      fullWidth
      PaperProps={{ sx: { minWidth: "300px", borderRadius: "20px" } }}
      open={openModal}
      onClose={handleCloseModal}
      onKeyUp={handleOnKeyUp}
    >
      <DialogTitle
        sx={{
          pb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: { xs: 6, sm: 2 },
          maxWidth: "85%",
          mx: "auto",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: { xs: "18px", sm: "24px" },
            fontWeight: 600,
            flexGrow: 1,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: { xs: 10, sm: 8 },
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ m: "auto", mb: 1, mt: 1, mx: 3 }} />
      {errorMsg && <Error errorMessage={errorMsg} sx={{ mx: 3 }} />}

      <DialogContent sx={{ ...styles }}>{children}</DialogContent>

      {actions ? (
        <DialogActions>{actions}</DialogActions>
      ) : (
        <DialogActions
          sx={{
            mx: 3,
            px: 0,
            minWidth: { xs: "250px", sm: "300px" },
          }}
        >
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              sx={{ width: { xs: "120px", sm: small ? "160px" : "180px" } }}
              variant="outlined"
              color="error"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{
                borderRadius: "20px",
                width: { xs: "120px", sm: small ? "160px" : "180px" },
              }}
              loading={loading}
              variant="outlined"
              onClick={handleSubmit}
              autoFocus
            >
              {submitButtonText || "Submit"}
            </LoadingButton>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
