import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";

import { getOfflineOrder } from "../../../api/OfflineOrders";
import Loading from "../../../components/Loading";
import { Box, Typography } from "@mui/material";

export default function MarkAsPaid({
  markAsPaidLoading,
  orderDetails,
  open,
  handleClose,
  onSubmit,
}) {
  const [loading, setLoading] = React.useState(true);

  const [total, setTotal] = React.useState(null);

  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      getOfflineOrder(orderDetails.id)
        .then((rsp) => {
          setLoading(false);
          const isTaxIncluded = rsp.fullOrder.order.isTaxIncluded;
          let taxTotal = 0;
          let totalPrice = 0;

          rsp?.lineItems.map((prod, index) => {
            taxTotal += prod.linePrice * prod.taxRate;
            totalPrice += prod.linePrice;
          });

          setTotal(totalPrice + (isTaxIncluded ? 0 : taxTotal));
        })
        .catch((err) => {
          console.error(err);
          setError("Error: Something went wrong, please try again later. ");
          setLoading(false);
        });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirmation</DialogTitle>

      {!loading && (
        <DialogContent>
          {!error && (
            <Typography>
              Confirm ${total?.toFixed(2)} received from{" "}
              {orderDetails?.fullName} ({orderDetails?.id}).
            </Typography>
          )}
          {error && <Typography color={"error"}>{error}</Typography>}
        </DialogContent>
      )}

      {loading && (
        <Box sx={{ width: "450px", height: "50px" }}>
          <Loading size={30} sx={{ mt: "5px", ml: 4 }} />
        </Box>
      )}

      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          No
        </Button>
        <LoadingButton
          variant="outlined"
          loading={markAsPaidLoading}
          onClick={onSubmit}
          disabled={loading || error}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
