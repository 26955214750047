import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

const OrderBanner = ({ products, order, onActionSelect }) => {
  const isCancelled = order["canceled"];
  const isUnpaid = order["orderpaymentstatus"] === "NOT_PAID";

  const isAllDelivered = products.every(
    (product) => product.shippingstatus === "DELIVERED"
  );

  const filteredProducts = products.filter(
    (product) => !product.pid.startsWith("XTRA")
  );

  const isPartiallyDelivered =
    filteredProducts.some(
      (product) => product.shippingstatus === "DELIVERED"
    ) && !isAllDelivered;

  const isAllShipped = filteredProducts.every(
    (product) => product.shippingstatus === "SHIPPED"
  );
  const isPartiallyShipped =
    filteredProducts.some((product) => product.shippingstatus === "SHIPPED") &&
    !isAllShipped;

  const isAllOnHold = filteredProducts.every(
    (product) => product.shippingstatus === "HOLD_SHIPMENT"
  );
  const isPartiallyOnHold =
    filteredProducts.some(
      (product) => product.shippingstatus === "HOLD_SHIPMENT"
    ) && !isAllOnHold;

  const getStatuses = () => {
    const statuses = [];
    if (isCancelled)
      statuses.push({ text: "CANCELLED", color: "#FF6B6B", clickable: false });
    if (isUnpaid)
      statuses.push({ text: "NOT PAID", color: "#FF9F43", clickable: false });
    if (isAllDelivered)
      statuses.push({ text: "DELIVERED", color: "#28C76F", clickable: false });
    else if (isPartiallyDelivered)
      statuses.push({
        text: "PARTIALLY DELIVERED",
        color: "#54B435",
        clickable: false,
      });
    if (isAllShipped && !isAllDelivered)
      statuses.push({ text: "SHIPPED", color: "#4D96FF", clickable: false });
    else if (isPartiallyShipped)
      statuses.push({
        text: "PARTIALLY SHIPPED",
        color: "#6C9BCF",
        clickable: false,
      });
    if (isAllOnHold)
      statuses.push({
        text: "ON HOLD",
        color: "#BA68C8",
        clickable: true,
        action: "holdOrder",
      });
    else if (isPartiallyOnHold)
      statuses.push({
        text: "PARTIAL HOLD",
        color: "#CF9FFF",
        clickable: true,
        action: "holdOrder",
      });
    return statuses;
  };

  const statuses = getStatuses();

  if (statuses.length === 0) return null;

  const StatusBanner = ({ status }) => (
    <Box
      component={status.clickable ? Button : Box}
      onClick={
        status.clickable ? () => onActionSelect(status.action) : undefined
      }
      variant={status.clickable ? "contained" : "inherit"}
      sx={{
        background: status.color,
        p: 1,
        width: 180,
        borderRadius: "2px",
        ml: "0 !important",
        "&:hover": status.clickable
          ? {
              background: status.color,
              opacity: 0.9,
            }
          : {},
      }}
    >
      <Typography color="white" textAlign="center">
        {status.text}
      </Typography>
    </Box>
  );

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 1, sm: 2 }}
      alignItems={"center"}
      sx={{
        alignContent: "center",
        flexWrap: { sm: "wrap" },
        gap: { sm: 1, md: 2 },
      }}
    >
      {" "}
      {statuses.map((status, index) => (
        <StatusBanner key={index} status={status} />
      ))}
    </Stack>
  );
};

export default OrderBanner;
