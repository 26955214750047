import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";

async function checkAppScriptVersionOk(ignoreServerNetworkError = true) {
  const scripts = Array.from(document.scripts);
  const mainAppScript = scripts.find((script) =>
    script.src.includes("/static/js/main")
  );
  const scriptUrl = mainAppScript?.src;

  if (!scriptUrl) {
    console.log("Main app script not found");
    return true;
  }

  try {
    const response = await fetch(scriptUrl, {
      method: "GET",
      cache: "no-store",
    });

    if (!response.ok) {
      console.log(
        `Failed to fetch ${scriptUrl}. HTTP Status: ${response.status}`
      );
      return !!ignoreServerNetworkError;
    }

    const scriptContent = await response.text();
    let isHtml = scriptContent.slice(0, 30).includes("<html");

    if (isHtml) {
      console.log(
        `Script URL ${scriptUrl} appears to be HTML not a script, show toast`
      );
      return false;
    }

    console.log(`App script current check OK`);
    return true;
  } catch (error) {
    console.log(
      `Network Error checking current version of script ${scriptUrl}:`,
      error
    );
    return !!ignoreServerNetworkError;
  }
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  position: "fixed",
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 10000,
  minWidth: "300px",
  boxShadow: theme.shadows[6],
  "& .MuiAlert-message": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const VersionChecker = () => {
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [checking, setChecking] = React.useState(false);

  useEffect(() => {
    const checkVersion = async () => {
      setChecking(true);
      const isCurrentVersion = await checkAppScriptVersionOk();
      if (!isCurrentVersion) {
        setShowUpdate(true);
      }
      setChecking(false);
    };

    // Initial check
    checkVersion();

    // Set up interval for subsequent checks
    const interval = setInterval(checkVersion, 10 * 60 * 1000); // 10 minutes

    return () => clearInterval(interval);
  }, []);

  return (
    <Slide in={showUpdate} direction="down" mountOnEnter unmountOnExit>
      <StyledAlert
        severity="info"
        icon={checking ? <CircularProgress size={20} /> : undefined}
      >
        New app version released,{" "}
        <Link
          component="button"
          onClick={() => window.location.reload()}
          underline="hover"
        >
          click to reload
        </Link>
      </StyledAlert>
    </Slide>
  );
};

export default VersionChecker;
