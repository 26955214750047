import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const menuItems = [
  { label: "Schedule Shipment", action: "scheduleShipment" },
  {
    label: "Ship Items",
    action: "shipItems",
  },
  {
    label: "Add Serial Numbers",
    action: "addSerialNumbers",
  },
  {
    label: "Update Hold Status",
    action: "updateHoldStatus",
  },
];

export const ActionMenu = ({ onActionSelect, selectedRows }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {selectedRows && (
        <Button
          id="dynamic-menu-button"
          aria-controls={open ? "dynamic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          Actions
        </Button>
      )}
      <Menu
        id="dynamic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dynamic-menu-button",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onActionSelect(item.action);
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
