import axios from "axios";
import Cookies from "js-cookie";
import { API_ORDER_LINK_URL } from "../consts";

const axiosInstance = axios.create({
  baseURL: API_ORDER_LINK_URL,
});

// Set
axiosInstance.interceptors.request.use(
  (config) => {
    const url = new URL(config.url, config.baseURL);
    const hostname = url.hostname;
    if (hostname.endsWith(".cloudfunctions.net") || hostname === "localhost") { // todo make more secure?
      const cookie = Cookies.get("_auth");
      if (cookie) {
        config.headers.authorization = `Bearer ${cookie}`;
      }
      if (!cookie && !url.pathname.includes("/login") && !url.pathname.includes("/wakeup")) {
        console.log("no auth cookie getting " + url.pathname)
      }
      config.withCredentials = true;
    }

    return config;
  },
  (error) => {
    console.log("Axios error\n", error);
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => response, // Simply return the response if everything is fine
//   async (error) => {
//     const originalRequest = error.config;
//
//     // Check if the response status is 401 and the request hasn't been retried yet
//     if (error?.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true; // Mark this request as retried
//
//       // Attempt to get a new access token using the refresh token
//       // Ensure withCredentials is set to true to include cookies in the request
//       const response = await axios.get(
//         `${API_ORDER_LINK_URL}/refreshToken`,
//         {},
//         {
//           withCredentials: true, // This is the correct way to include cookies with Axios
//         }
//       );
//
//       const newAccessToken = response.data.accessToken;
//       if (newAccessToken) {
//         Cookies.set("_auth", newAccessToken);
//         // Update the authorization header with the new access token and resend the original request
//         originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
//       }
//       // Update the access token in the cookies or wherever you store it
//
//       return axiosInstance(originalRequest);
//     }
//     // Return any error which is not due to authorization
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
