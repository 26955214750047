import React from "react";
import Modal from "../../../components/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const CancelOrderModal = ({
  cancelOrderLoading,
  orderDetails,
  open,
  handleClose,
  onSubmit,
}) => {
  return (
    <Modal
      title="Cancel Order"
      openModal={open}
      handleCloseModal={handleClose}
      handleSubmit={onSubmit}
      loading={cancelOrderLoading}
      maxWidth="sm"
      submitButtonText={"Confirm"}
    >
      <Box sx={{ py: 2 }}>
        <Typography variant="h6" gutterBottom>
          Are you sure you want to cancel this order?
        </Typography>
        <Typography color="text.secondary">
          Order: {orderDetails?.id}
        </Typography>
      </Box>
    </Modal>
  );
};

export default CancelOrderModal;
