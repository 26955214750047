import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import TextField from "../TextField";
import FilterComponent, { AddButton } from "./Container";
import Divider from "@mui/material/Divider";
import Modal from "../Modal";
import { loadUser } from "../../common";
import useMediaQuery from "@mui/material/useMediaQuery";

const FilterModal = ({
  isEdit,
  handleAddFilterSubmit,
  handleUpdateFilterSubmit,
  filterLoading,
  searchFieldOptions,
  selectedFilter,
  openModal,
  handleCloseModal,
  errorMessage,
  table,
}) => {
  const [isCreator, setIsCreator] = React.useState(false);
  const [isShared, setIsShared] = React.useState(null);
  const [isTemporary, setIsTemporary] = React.useState(false);
  const [filters, setFilters] = React.useState(null);
  const [name, setName] = React.useState("");
  const [andOr, setAndOr] = React.useState(10);
  const [nameError, setNameError] = React.useState(false);

  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!openModal) return;

    const isUserCreator = selectedFilter
      ? selectedFilter.userID === JSON.parse(loadUser()).id
      : true;

    const initialFilters = selectedFilter
      ? selectedFilter.filters
      : [
          {
            ...searchFieldOptions[0],
            displayName: searchFieldOptions[0].label,
            type: 0,
            value: "",
            error: false,
          },
        ];
    setIsCreator(isUserCreator);
    setIsShared(selectedFilter?.isShared || false);
    setIsTemporary(selectedFilter?.isTemporary || false);
    setFilters(initialFilters);
    setName(selectedFilter?.name || "");
    setAndOr(selectedFilter ? (selectedFilter.andOr === "and" ? 10 : 20) : 10);
    setNameError(false);
  }, [openModal, selectedFilter, searchFieldOptions]);

  const handleShareChange = (event) => {
    const newSharedValue = event.target.checked;
    setIsShared(newSharedValue);
    if (newSharedValue) {
      setIsTemporary(false); // Disable temporary if shared is selected
    }
  };

  const handleTemporaryChange = (event) => {
    const newTemporaryValue = event.target.checked;
    setIsTemporary(newTemporaryValue);
    if (newTemporaryValue) {
      setIsShared(false); // Disable shared if temporary is selected
    }
  };

  const handleName = (value) => setName(value);

  const addFilter = () => {
    const newFilter = {
      dataType: searchFieldOptions[0].dataType,
      displayName: searchFieldOptions[0].label,
      fieldName: searchFieldOptions[0].fieldName,
      searchTypes: searchFieldOptions[0].searchTypes,
      type: 0,
      value: "",
      error: false,
    };

    setFilters([...filters, newFilter]);
  };

  const updateFilter = (index, updatedData) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter, i) =>
        i === index
          ? {
              ...filter,
              ...updatedData,
              value:
                filter.fieldName !== updatedData.fieldName
                  ? filter.value
                  : updatedData.value,
            }
          : filter
      )
    );
  };

  const removeFilter = (index) =>
    setFilters(filters.filter((_, i) => i !== index));

  useEffect(() => {
    if (filters?.length === 0) {
      addFilter();
    }
  }, [filters]);

  const validateSubmit = () => {
    const validatedFilters = filters.map((filter) => {
      const type = filter.searchTypes[filter.type];
      const isBlank = type === "Is blank" || type === "Is not blank";
      return {
        ...filter,
        error:
          !isBlank &&
          (filter.value === "" ||
            (filter.fieldName === "everywhere" && filter.value.length < 3)) &&
          (!filter.values || filter.values.length === 0),
      };
    });

    setFilters(validatedFilters);
    setNameError(false);
    const hasError = validatedFilters.some((filter) => filter.error);
    const isNameValid = !!name;
    if (hasError || !isNameValid) {
      setNameError(!isNameValid);
      return;
    }

    const submitData = {
      name,
      isShared,
      isTemporary,
      filters: validatedFilters,
      andOr: andOr === 10 ? "and" : "or",
    };

    if (isEdit) {
      handleUpdateFilterSubmit({ ...submitData, id: selectedFilter.id });
    } else {
      handleAddFilterSubmit(submitData);
    }
  };

  const modalContent = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            sx={{ mt: "5px", width: { xs: "100%", sm: "80%", md: "60%" } }}
            label={"Name"}
            value={name}
            changeValue={handleName}
            error={nameError && "This field is required. "}
          />
        </Box>
      </Box>
      <Box mt={1}>
        {isCreator && (
          <Box display="flex" gap={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isShared}
                  onChange={handleShareChange}
                  name="shareFilter"
                  color="primary"
                  disabled={isTemporary}
                />
              }
              label="Share Filter"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTemporary}
                  onChange={handleTemporaryChange}
                  name="temporaryFilter"
                  color="primary"
                  disabled={isShared}
                />
              }
              label="Temporary"
              title={"Temporary filters will be deleted when you click 'Clear Filter'"}
            />
          </Box>
        )}
      </Box>
      <Box mt={isCreator ? 1 : 2} mb={1}>
        <Typography sx={{ mb: 2 }}>
          Show rows that match all conditions:{" "}
        </Typography>
        <Box>
          {searchFieldOptions &&
            filters?.map((filter, index) => (
              <React.Fragment key={index}>
                <FilterComponent
                  index={index}
                  filter={filter}
                  onRemove={() => removeFilter(index)}
                  onUpdate={(updatedData) => updateFilter(index, updatedData)}
                  table={table}
                  searchFieldOptions={searchFieldOptions}
                />
                {filters?.length > 1 && <Divider sx={{ mt: 3 }} />}
              </React.Fragment>
            ))}
          <Box mt={2.5}>
            <Grid container spacing={2}>
              {filters?.length < 2 ? (
                <Grid item xs={11}></Grid>
              ) : (
                <Grid item xs={12} sm={11}>
                  <FormControl
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "20px",
                        background: "white",
                      },
                    }}
                  >
                    <InputLabel>AND/OR</InputLabel>
                    <Select
                      label="AND/OR"
                      value={andOr}
                      onChange={(e) => setAndOr(e.target.value)}
                    >
                      <MenuItem value={10}>AND</MenuItem>
                      <MenuItem value={20}>OR</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={1}
                display="flex"
                justifyContent={isSm ? "flex-end" : "inherit"}
              >
                <FormControl
                  sx={{
                    display: "flex-inline",
                    mr: "8px",
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      background: "white",
                    },
                  }}
                >
                  <AddButton addFilter={addFilter} />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );

  return (
    <Modal
      title={isEdit ? `Edit Filter: ${selectedFilter.name}` : "New Filter"}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
      handleSubmit={validateSubmit}
      loading={filterLoading}
      errorMsg={errorMessage}
      maxWidth="lg"
      submitButtonText="Apply"
    >
      {modalContent}
    </Modal>
  );
};

export default FilterModal;
