import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import TextField from "@mui/material/TextField";

const AddNotes = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    const order = data.orders[0];
    setState({
      notes: order?.notes,
      warehouseOrderNumber: order?.warehouseordernumber,
      rowNumber: order?.rownumber,
    });
  }, [data]);

  const handleChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      notes: event.target.value,
    }));
  };

  return (
    <Box mb={1}>
      <TextField
        sx={{ width: "100%" }}
        label={"Notes"}
        value={state.notes}
        onChange={handleChange}
        multiline
        rows={4}
      />
    </Box>
  );
};

export default AddNotes;
