import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ProductTile from "../components/ProductTile";
import FreightFiller from "../../../components/FreightFiller";
import AddProductModal from "../components/AddProductModal";
import WarehouseSelect from "../../InventoryOrders/components/warehouseSelect";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Loading from "../../../components/Loading";
import CalculateIcon from "@mui/icons-material/Calculate";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useLocation, useNavigate } from "react-router-dom";

const BuildInventoryOrderScreen = ({
  error,
  orderData,
  onCreateOrder,
  handleAddProduct,
  handleProductsChange,
  handleDeleteOrder,
  handleLockedPidChange,
  selectedWarehouse,
  handleWarehouseChange,
  warehouseProducts,
  containerSize,
  handleContainerSizeChange,
  fetchRecommendedQuantities,
  lockedProducts,
  hideFreightFiller,
}) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const warehouseOrderNumber = searchParams.get("warehouseOrderNumber");
  const isMedium = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { products } = orderData;

  const [isResetConfirmOpen, setIsResetConfirmOpen] = useState(false);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [calculateLoading, setCalculateLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const handleQuantityChange = (productId, newQuantity) => {
    handleProductsChange(
      newQuantity < 0 ? 0 : Math.round(newQuantity),
      "qty",
      productId
    );
  };

  const handleRecalculate = async () => {
    setCalculateLoading(true);
    await fetchRecommendedQuantities();
    setCalculateLoading(false);
  };

  const handleResetConfirm = () => {
    setIsResetConfirmOpen(true);
  };

  const handleResetCancel = () => {
    setIsResetConfirmOpen(false);
  };

  const handleResetConfirmed = async () => {
    setResetLoading(true);
    await fetchRecommendedQuantities(true);
    setResetLoading(false);
    setIsResetConfirmOpen(false);
  };

  const isLoading = !warehouseProducts || !orderData.products;

  const handleCancel = () => {
    if (warehouseOrderNumber) {
      navigate(`/inventoryOrder/${warehouseOrderNumber}`);
    } else navigate("/inventoryOrders/stockOrders");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          my: 1,
          py: 1,
          px: 2,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            flexWrap: isMedium ? "wrap" : "nowrap",
            alignItems: "center",
            gap: 2,
            pt: { xs: 1, md: 1, lg: hideFreightFiller ? 1 : 0 },
            pb: hideFreightFiller ? 1 : 0,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "stretch" : "center",
              gap: 2,
              width: isMobile ? "100%" : "auto",
            }}
          >
            <WarehouseSelect
              warehouses={["AUGC", "CAVC", "USTX", "NZAKL", "CNFS"]}
              selectedWarehouse={selectedWarehouse}
              handleWarehouseChange={handleWarehouseChange}
            />
            <FormControl
              variant="outlined"
              color="primary"
              size="small"
              sx={{ minWidth: 120 }}
            >
              <InputLabel>Order Size</InputLabel>
              <Select
                value={containerSize}
                onChange={handleContainerSizeChange}
                label="Order Size"
              >
                <MenuItem value="20">20ft</MenuItem>
                <MenuItem value="40">40ft</MenuItem>
                <MenuItem value="allNeeded">All Needed</MenuItem>
                <MenuItem value="blank">Blank</MenuItem>
                <MenuItem value="LCL">LCL</MenuItem>
                <MenuItem value="Express">Express</MenuItem>
                <MenuItem value="Ground">Ground</MenuItem>
              </Select>
            </FormControl>{" "}
            <LoadingButton
              onClick={handleResetConfirm}
              variant="outlined"
              startIcon={<RestartAltIcon />}
              fullWidth={isMobile}
            >
              Reset
            </LoadingButton>
            <LoadingButton
              onClick={handleRecalculate}
              variant="outlined"
              startIcon={<CalculateIcon />}
              loading={calculateLoading}
              fullWidth={isMobile}
            >
              Recalculate
            </LoadingButton>
          </Box>

          {!hideFreightFiller && (
            <Box
              sx={{
                mt: isMobile ? 2 : 0,
                minWidth: { xs: "100%", sm: 400 },
              }}
            >
              <FreightFiller
                volumeFieldName={"cubicVolume"}
                quantitiyFieldName={"qty"}
                containerType={
                  containerSize === "allNeeded" || containerSize === "blank"
                    ? 40
                    : containerSize === "LCL"
                    ? 20
                    : containerSize
                }
                products={warehouseProducts}
                rows={orderData.products}
              />
            </Box>
          )}
        </Box>
      </Box>

      {isLoading ? (
        <Loading sx={{ width: 40, mx: "auto", mt: 4 }} />
      ) : (
        <Box
          sx={{
            my: 1,
            mt: 0,
            px: { xs: 1, sm: 3 },
            height: {
              xs: `calc(100vh - ${
                hideFreightFiller ? (error ? 295 : 343) : error ? 433 : 380
              }px)`,
              sm: `calc(100vh - ${
                hideFreightFiller ? (error ? 357 : 308) : error ? 445 : 395
              }px)`,
              md: `calc(100vh - ${
                hideFreightFiller ? (error ? 190 : 140) : error ? 205 : 155
              }px)`,
              lg: `calc(100vh - ${
                hideFreightFiller ? (error ? 190 : 140) : error ? 197 : 145
              }px)`,
            },
            overflow: "auto",
          }}
        >
          <Grid container spacing={2}>
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={product?.pid}>
                <ProductTile
                  isLocked={lockedProducts[product.pid]}
                  product={product}
                  onQuantityChange={handleQuantityChange}
                  onLockToggle={handleLockedPidChange}
                  handleDeleteOrder={handleDeleteOrder}
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ position: "relative", height: "100%", minHeight: 425 }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <IconButton
                    sx={{ pr: 1 }}
                    onClick={() => setIsAddModalOpen(true)}
                  >
                    <AddCircleIcon fontSize={"large"} color={"primary"} />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          </Grid>{" "}
        </Box>
      )}

      <Box
        sx={{
          position: "fixed",
          bottom: { xs: 10, sm: 20 },
          right: { xs: 10, sm: 20 },
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            onCreateOrder({ products, selectedWarehouse, containerSize })
          }
          variant="contained"
          color="primary"
          disabled={isLoading || orderData.products?.length === 0}
          sx={{
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.dark,
              color: "white",
            },
          }}
        >
          {warehouseOrderNumber ? "Update Order" : "Create Order"}
        </Button>
      </Box>
      <Dialog
        open={isResetConfirmOpen}
        onClose={handleResetCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Reset"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to reset? This action will clear all locked
            products.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetCancel}>Cancel</Button>
          <LoadingButton
            loading={resetLoading}
            onClick={handleResetConfirmed}
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <AddProductModal
        open={isAddModalOpen}
        handleClose={() => setIsAddModalOpen(false)}
        warehouseProducts={warehouseProducts || []}
        addProduct={handleAddProduct}
      />
    </Box>
  );
};

export default BuildInventoryOrderScreen;
