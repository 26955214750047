import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteSettings, getSettings } from "../../../api/userSettings";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";

const DisplaySettings = () => {
  const [groupedSettings, setGroupedSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeletedLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    type: "",
    dataGridName: "",
  });

  const formatDisplayText = (text) => {
    const spacedText = text.replace(/([A-Z])/g, " $1");
    return spacedText.charAt(0).toUpperCase() + spacedText.slice(1).trim();
  };

  const fetchSettings = async () => {
    try {
      const response = await getSettings("", "");
      setGroupedSettings(response.settings);
    } catch (error) {
      console.error("Error fetching settings:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleDeleteClick = (type, dataGridName) => {
    setDeleteDialog({
      open: true,
      type,
      dataGridName,
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      setDeletedLoading(true);
      await deleteSettings(deleteDialog.type, deleteDialog.dataGridName);
      await fetchSettings(); // Refresh the settings list
    } catch (error) {
      console.error("Error deleting setting:", error);
    } finally {
      setDeleteDialog({ open: false, type: "", dataGridName: "" });
      setDeletedLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialog({ open: false, type: "", dataGridName: "" });
  };

  const renderSettingsList = (type, settings) => {
    return (
      <Paper elevation={1} sx={{ mb: 2, p: 3 }}>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: 500,
            color: "text.primary",
          }}
        >
          {formatDisplayText(type)}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {settings.map((setting) => (
            <Paper
              key={setting.id}
              elevation={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                px: 2,
                "&:hover": {
                  bgcolor: "action.hover",
                },
              }}
            >
              <Typography
                sx={{
                  color: "text.secondary",
                  fontSize: "0.95rem",
                }}
              >
                {setting.dataGridName === "order"
                  ? "Customer Order"
                  : setting.dataGridName
                  ? formatDisplayText(setting.dataGridName)
                  : "All"}
              </Typography>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteClick(type, setting.dataGridName)}
                sx={{
                  color: "text.secondary",
                  "&:hover": {
                    color: "error.main",
                  },
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Paper>
          ))}
        </Box>
      </Paper>
    );
  };

  if (loading) {
    return;
  }

  return (
    <Box sx={{ mt: 4, maxWidth: 800, mx: "auto" }}>
      <Typography variant="h5">Settings Overview</Typography>
      <Divider sx={{ mb: 3, mt: 1 }} />
      {Object.entries(groupedSettings).map(([type, settings]) => (
        <React.Fragment key={type}>
          {renderSettingsList(type, settings)}
        </React.Fragment>
      ))}

      {/* Confirmation Dialog */}
      <Dialog open={deleteDialog.open} onClose={handleDeleteCancel}>
        <DialogTitle>Reset Setting</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to reset this setting? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <LoadingButton
            onClick={handleDeleteConfirm}
            color="error"
            loading={deleteLoading}
          >
            Reset
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DisplaySettings;
