import { styled } from "@mui/material/styles";
import { DataGridPremium } from "@mui/x-data-grid-premium";

export const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  // width:"100%",
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "--unstable_DataGrid-radius": "0px",

  borderRadius: "0px",
  ".MuiDataGrid-actionsCell": {
    margin: "auto",
  },
  "& .MuiDataGrid-scrollbar": { display: "block" },
  "&.MuiDataGrid-columnHeaderTitle": {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },

  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
    padding: 0,
    fontSize: 12,
    borderBottom: "1px solid rgb(227 227 227)",
  },
  "& .MuiDataGrid-cell--textLeft": { padding: "0 10px" },

  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "normal",
    fontSize: "12px",
  },
  "& .MuiDataGrid-columnHeaders": {
    // Forced to use important since overriding inline styles
    maxHeight: "168px !important",
    background: "white",
  },
  ".MuiTypography-root": {
    overflow: "hidden",
    display: "-webkit-box",
    padding: "3px",
  },
  ".MuiDataGrid-detailPanelToggleCell": {
    margin: "auto",
  },
  ".missingItemPID-theme .MuiDataGrid-cell": {
    background: "#ff8787 !important",
  },
  ".missingItemPID-theme .MuiDataGrid-cell .MuiBox-root": {
    background: "#ff8787 !important",
  },
  ".inProgressMarkAsPaid-theme .MuiDataGrid-cell": {
    background: "#DCDCDC !important",
  },
  ".inProgressMarkAsPaid-theme .MuiDataGrid-cell .MuiBox-root": {
    background: "#DCDCDC !important",
  },
  ".cancelled-theme .MuiDataGrid-cell .MuiBox-root": {
    background: "#DCDCDC !important",
  },
  ".cancelled-theme .MuiDataGrid-cell": { background: "#DCDCDC !important" },
  ".unpaid-theme .MuiDataGrid-cell": {
    background: "#C8B4AA !important",
  },
  ".unpaid-theme .MuiDataGrid-cell .MuiBox-root": {
    background: "#C8B4AA !important",
  },
  ".MuiDataGrid-columnHeader": {
    background: "#efefef",
    borderRight: "1px solid rgb(227 227 227)",
    borderBottom: "1px solid rgb(227 227 227)",
  },
  ".MuiDataGrid-virtualScroller": {
    background: "white",
  },
  ".MuiDataGrid-footerContainer": {
    background: "white",
  },
  "& .MuiDataGrid-row.Mui-hovered": {
    backgroundColor: "white",
  },

  "& .grid-row .MuiDataGrid-cell .MuiBox-root": {
    background: "#399cea !important",
  },
  "& .grid-row .MuiDataGrid-cell": {
    background: "#399cea !important",
    color: "white !important",
    "& .MuiTypography-root": {
      color: "white !important",
    },
    "& .MuiSvgIcon-root": {
      color: "white !important",
    },
  },

  ".MuiDataGrid-columnHeader .MuiDataGrid-menuIcon": {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0.3s, opacity 0.3s",
  },

  ".MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon": {
    visibility: "visible",
    opacity: 1,
  },

  ".MuiDataGrid-pinnedRows .grid-row .MuiDataGrid-cell": {
    background: "white !important",
  },
}));
