import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import { permissionsState } from "../atoms/atoms";
import { useSetRecoilState } from "recoil";
import useSignOut from "react-auth-kit/hooks/useSignOut";

import { getRole } from "../api/Roles";

import Loading from "./Loading";
import Box from "@mui/material/Box";
import { loadUser } from "../common";
import AuthExpiryCheck from "./AuthExpiryCheck";
import { jwtDecode } from "jwt-decode";

const PermissionGate = ({ children }) => {
  let navigate = useNavigate();

  const signOut = useSignOut();

  const [cookies] = useCookies(["_auth"]);
  const [loading, setLoading] = React.useState(true);

  const setPermissions = useSetRecoilState(permissionsState);

  React.useEffect(() => {
    // Monitors the cookies._auth, and signs the user out if it's no longer there
    try {
      const user = JSON.parse(loadUser())?.id;
      let cookie2ndOpinion = Cookies.get("_auth")
      if (!user || !cookies._auth) {
        console.log(`About to sign out.  Has? user:${!!user}, cookies._auth ${!!cookies._auth}, js-cookie _auth ${!!cookie2ndOpinion}`)
        setTimeout(() => {
          let cookie3rdOpinion = Cookies.get("_auth")
          console.log(`Siging out.  Has? user:${!!user}, cookies._auth ${!!cookies._auth}, js-cookie _auth (fresh) ${!!cookie3rdOpinion}`)
          console.log("Signout")
          signOut();
          if (!window.location.pathname.startsWith("/login")) {
            navigate("/login");
          }
        }, 0)
      }
    } catch (error) {
      console.log("PermissionGate error\n", error);
    }
  }, [cookies._auth, navigate, signOut]);

  React.useEffect(() => {
    let didComponentMount = false;

    try {
      let decodedToken = jwtDecode(cookies["_auth"]);

      getRole(decodedToken.roleID).then((rsp) => {
        if (didComponentMount) return;
        if (rsp.role) {
          setPermissions(rsp.role);
        } else {
          console.error(`Error fetching role (${decodedToken.roleID})`);
        }
        setLoading(false);
      });
    } catch (err) {
      let errMessage = err.name ? err.name + " " + err.message : err.message;
      console.log(errMessage, "\n> PermissionGate is now signing out user");
      signOut();
      if (window.location.pathname.startsWith("/login")) {
        console.log("already at login");
      } else {
        console.log("to login >>>");
        navigate("/login");
      }
    }

    return () => {
      didComponentMount = true;
    };
  }, []);

  return (
    <>
      <AuthExpiryCheck />
      {loading && (
        <Box sx={{ height: "100vh", background: "white" }}>
          <Loading
            size={60}
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 4,
              pt: "300px",
            }}
          />
        </Box>
      )}
      {!loading && children}
    </>
  );
};

export default PermissionGate;
