import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import Table from "../../../components/Table";
import Divider from "@mui/material/Divider";

const DistributeOrderCosts = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders,
    });
  }, [data.orders, setState]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        ),
      }));
    },
    [setState]
  );

  const customsColumns = [
    {
      field: "customscurrency",
      headerName: "Currency",
      width: 150,
      editable: true,
      type: "currency",
    },
    {
      field: "customsfees",
      headerName: "Fees",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "customsdatepaid",
      headerName: "Date Paid",
      width: 200,
      editable: true,
      type: "date",
    },
  ];

  const internationalColumns = [
    {
      field: "currencyintshipping",
      headerName: "Currency",
      width: 150,
      editable: true,
      type: "currency",
    },
    {
      field: "internationalshipmentcost",
      headerName: "Cost",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "intshipdatepaid",
      headerName: "Date Paid",
      width: 200,
      editable: true,
      type: "date",
    },
  ];

  const localColumns = [
    {
      field: "currencylocalshipping",
      headerName: "Currency",
      width: 150,
      editable: true,
      type: "currency",
    },
    {
      field: "localshipmentcost",
      headerName: "Cost",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "locshipdatepaid",
      headerName: "Date Paid",
      width: 200,
      editable: true,
      type: "date",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Typography variant="body1" color="text.secondary">
        Distribute costs for order{" "}
        {!!state.rows && state?.rows[0].warehouseordernumber}.
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        {/* Customs Section */}
        <Box>
          <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
            Customs
          </Typography>
          <Table
            columns={customsColumns}
            rows={state.rows}
            onCellChange={handleInputChange}
            globalTimezone={data.orders[0].warehouse.slice(0, 2)}
          />
        </Box>

        <Divider />

        {/* International Shipping Section */}
        <Box>
          <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
            International Shipping
          </Typography>
          <Table
            columns={internationalColumns}
            rows={state.rows}
            onCellChange={handleInputChange}
            globalTimezone={data.orders[0].warehouse.slice(0, 2)}
          />
        </Box>

        <Divider />

        {/* Local Shipping Section */}
        <Box>
          <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
            Local Shipping
          </Typography>
          <Table
            columns={localColumns}
            rows={state.rows}
            onCellChange={handleInputChange}
            globalTimezone={data.orders[0].warehouse.slice(0, 2)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DistributeOrderCosts;
