import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { useMediaQuery, useTheme } from "@mui/material";

const CONTAINER_SIZES = {
  40: 76, // 40ft container can be filled to an absolute max of 76 m³
  20: 33, // 20ft container can be filled to an absolute max of 33 m³
};

const ContainerVisualization = ({
  volumeFieldName,
  quantitiyFieldName,
  containerType,
  rows,
  products,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const containerSize = CONTAINER_SIZES[containerType];

  const getVolume = (row, product) => {
    if (row[volumeFieldName] !== undefined) {
      return row[volumeFieldName];
    }
    if (product && product[volumeFieldName] !== undefined) {
      return product[volumeFieldName];
    }
    return 0;
  };

  let totalVolume = 0;

  rows?.forEach((row) => {
    const quantity = row[quantitiyFieldName] || 1;
    const selectedProductId = row.pid;

    const product = products
      ? products?.find((product) => product.pid === selectedProductId)
      : null;

    const productVolume = getVolume(row, product);
    const rowVolume = productVolume * quantity;

    if (rowVolume) {
      totalVolume += rowVolume;
    } else if (isNaN(rowVolume)) {
      console.error(
        `FreightFiller: rowVolume NaN error. r.pid:${row.pid} p.pid:${product?.pid} q:${quantity} v:${productVolume} volumeFieldName:${volumeFieldName}`
      );
    }
  });

  const fillPercentage = (totalVolume / containerSize) * 100;

  // Calculate the width of the filled area (up to 80% of the current container)
  const filledWidth = Math.min(fillPercentage);

  // The lined area is always 20% of the container
  const linedWidth =
    fillPercentage > 80 && fillPercentage < 100
      ? 100 - fillPercentage
      : fillPercentage > 100
      ? 0
      : 20;

  const overLimitWidth =
    fillPercentage > 80 && fillPercentage < 100
      ? 20 - linedWidth
      : fillPercentage > 100
      ? 100
      : 0;

  return (
    <Box width="100%" maxWidth="452px">
      <Box display="flex" alignItems="flex-start">
        <DirectionsBoatIcon sx={{ mr: 1.5, mt: 4 }} />
        <Box flexGrow={1}>
          <Box display="flex" justifyContent="space-between" mb={0.5}>
            <Typography variant="caption">0ft</Typography>
            <Typography variant="caption">{containerType / 2}ft</Typography>
            <Typography variant="caption">{containerType}ft</Typography>
          </Box>
          <Box
            height="40px"
            display="flex"
            sx={{ border: 1, borderColor: "grey.300", position: "relative" }}
          >
            <Box
              height="100%"
              sx={{ backgroundColor: "#b9ddfc" }}
              style={{ width: `${filledWidth < 100 ? filledWidth : 0}%` }}
            />
            <Box
              height="100%"
              sx={{
                background: overLimitWidth === 100 ? "#FF9999" : "",
                backgroundImage:
                  overLimitWidth === 100
                    ? ""
                    : "repeating-linear-gradient(45deg, #FF6600, #FF6600 10px, #FF8C00 10px, #FF8C00 20px)",
              }}
              style={{ width: `${overLimitWidth}%` }}
            />
            {filledWidth > 0 && (
              <Box
                height="100%"
                sx={{
                  backgroundImage:
                    "repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 10px, #e0e0e0 10px, #e0e0e0 20px)",
                }}
                style={{ width: `${linedWidth}%` }}
              />
            )}
            {fillPercentage > 0 && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  position: "absolute",
                  right: isMobile ? 4 : "auto",
                  left: isMobile ? "auto" : 4,
                  top: "50%",
                  transform: "translateY(-50%)",
                  whiteSpace: "nowrap",
                }}
              >
                {fillPercentage.toFixed(0)}% Full
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContainerVisualization;
