import React, { memo } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { TableCellComponent } from "./TableCellComponent";

export const TransposedTableRowComponent = memo(
  ({ column, rows, handleInputChange, globalTimezone }) => {
    return (
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          sx={{
            fontWeight: "bold",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            minWidth: 200,
            maxWidth: "250px !important",
            width: "250px",
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "12px 16px",
          }}
        >
          {column.headerName}
          {column.required && (
            <span style={{ color: "red", marginLeft: "4px" }}>*</span>
          )}
        </TableCell>
        {rows?.map((row) => {
          if (column.hide && column.hide(row)) {
            return null;
          }
          return (
            <TableCellComponent
              key={row.id}
              column={column}
              row={row}
              handleInputChange={handleInputChange}
              globalTimezone={globalTimezone}
            />
          );
        })}
      </TableRow>
    );
  }
);
