import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getProductsByCountryAndWarehouse } from "../../../api/Products";
import { useRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";

const AddFreeProductToOrder = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initialWarehouse = ["AUGC", "USTX", "CNFS"].includes(
      data.order?.warehouse
    )
      ? data.order?.warehouse
      : "";
    const wixOrderNumber = data.order?.wixordernumber;
    setState({
      product: null,
      qty: 1,
      warehouse: initialWarehouse,
      receivedPayment: false,
      wixOrderNumber: wixOrderNumber,
    });
  }, []);

  useEffect(() => {
    if (state.warehouse) {
      setLoading(true);
      setProducts(null);

      getProductsByCountryAndWarehouse(
        state.warehouse.slice(0, 2),
        state.warehouse
      )
        .then((rsp) => {
          setProducts(rsp.countryProducts);
          setLoading(false);
        })
        .catch((err) => {
          setProducts([]);
          setLoading(false);
        });
    }
  }, [state.warehouse]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setState((prevData) => ({
      ...prevData,
      [name]: name === "receivedPayment" ? checked : value,
    }));
  };

  // New function to filter options based on both PID and description
  const filterOptions = (options, { inputValue }) => {
    const searchTerm = inputValue.toLowerCase();
    return options.filter(
      (option) =>
        option.pid.toLowerCase().includes(searchTerm) ||
        (option.description &&
          option.description.toLowerCase().includes(searchTerm))
    );
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <FormControl fullWidth required>
        <InputLabel>Warehouse</InputLabel>
        <Select
          name="warehouse"
          label="Warehouse"
          value={state.warehouse || ""}
          onChange={handleChange}
        >
          <MenuItem value="USTX">USTX</MenuItem>
          <MenuItem value="AUGC">AUGC</MenuItem>
          <MenuItem value="CNFS">CNFS</MenuItem>
        </Select>
      </FormControl>
      <Autocomplete
        fullWidth
        options={products || []}
        getOptionLabel={(option) => {
          const desc = option.description ? option.description : "N/A";
          return `${desc} (${option.pid})`;
        }}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search products"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        value={state.product}
        onChange={(_, newValue) => {
          setState((prevState) => ({ ...prevState, product: newValue }));
        }}
        isOptionEqualToValue={(option, value) => option.pid === value.pid}
        renderOption={(props, option) => (
          <li {...props}>
            <div>
              <Typography variant="body1">
                {option.description || "N/A"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {option.pid}
              </Typography>
            </div>
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={`${option.description || "N/A"} (${option.pid})`}
              {...getTagProps({ index })}
            />
          ))
        }
        loading={loading}
      />
      <TextField
        name="qty"
        label="Quantity"
        type="number"
        value={state.qty}
        onChange={handleChange}
        fullWidth
        required
        InputProps={{ inputProps: { min: 1 } }}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="receivedPayment"
            checked={state.receivedPayment}
            onChange={handleChange}
          />
        }
        label="Received Payment"
      />
    </Box>
  );
};

export default AddFreeProductToOrder;
