import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { TransposedTableRowComponent } from "./TransposedTableRowComponent";
import TableContainer from "@mui/material/TableContainer";

const TransposedTable = ({
  columns,
  rows,
  handleInputChange,
  handleDelete,
  globalTimezone,
  maxHeight,
}) => (
  <TableContainer sx={{ maxHeight: maxHeight || "auto", overflow: "auto" }}>
    <Table>
      <TableBody>
        {columns?.map((column) => (
          <TransposedTableRowComponent
            key={column.field}
            column={column}
            rows={rows}
            handleInputChange={handleInputChange}
            globalTimezone={globalTimezone}
          />
        ))}
        {handleDelete && (
          <TableRow>
            <TableCell
              sx={{
                fontWeight: "bold",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              Delete
            </TableCell>
            {rows?.map((row) => (
              <TableCell key={row.id} sx={{ textAlign: "center" }}>
                <IconButton onClick={() => handleDelete(row.id)} size="small">
                  <DeleteIcon color="primary" />
                </IconButton>
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TransposedTable;
