import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function getShippingOption(orderId) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/getShippingOption?orderId=${orderId}
  `
    )
    .then((response) => response.data);
}

function getShippingPrice({ total, countryCode, city, postcode }) {
  const params = new URLSearchParams({
    total: total.toString(),
    ...(countryCode && { countryCode }),
    ...(city && { city }),
    ...(postcode && { postcode }),
  });

  const url = `${API_ORDER_LINK_URL}/getShippingPrices?${params.toString()}`;

  return axios.get(url).then((response) => response.data);
}

function getTaxIncluded(countryCode) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/getTaxIncluded?countryCode=${countryCode}
    `
    )
    .then((response) => response.data);
}

function getSalesTaxRate(
  countryCode,
  shippingCountry,
  shippingState,
  subtotal
) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/getSalesTaxRate?countryCode=${countryCode}&shippingCountryIso3=${shippingCountry}&shippingState=${shippingState}&subtotal=${subtotal}`
    )
    .then((response) => response.data);
}

function markAsPaid(orderId) {
  return axios
    .post(`${API_ORDER_LINK_URL}/setOfflineMarkedAsPaid`, { orderId: orderId })
    .then((response) => response.data);
}

export {
  markAsPaid,
  getShippingOption,
  getShippingPrice,
  getTaxIncluded,
  getSalesTaxRate,
};
