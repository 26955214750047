import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRecoilValue } from "recoil";
import { permissionsState } from "../../../atoms/atoms";

const OrderActionsMenu = ({ products, onActionSelect }) => {
  const permissions = useRecoilValue(permissionsState).customerOrders.actions;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => () => {
    setAnchorEl(null);
    if (action) {
      if (action === "createCustomerTicket") {
        window.open(process.env.REACT_APP_SMARTSHEET_TICKET_FORM_URL, "_blank");
      } else {
        onActionSelect(action);
      }
    }
  };
  // Define conditions based on all products
  const canShipItems = products.find((product) =>
    [
      "ORDER_CREATED",
      "PROCESSING_ORDER",
      "READY_FOR_SHIPMENT",
      "HOLD_SHIPMENT",
    ].includes(product.shippingstatus)
  );

  const canMarkAsDelivered =
    products.some((product) => {
      return (
        [
          "SHIPPED",
          "SYS_ARRIVED_AT_CUSTOMS",
          "SYS_CUSTOMS_DELAY",
          "SYS_CUSTOMS_CLEARED",
          "SYS_TRANSIT_DELAY",
          "OUT_FOR_DELIVERY",
          "AWAITING_COLLECTION",
          "ATTEMPTED_DELIVERY",
          "SYS_SHIPMENT_LOST",
        ].includes(product.shippingstatus) &&
        product.pid.split("-")[0] !== "XTRA"
      );
    }) &&
    !products.every((product) => {
      return ["DELIVERED"].includes(product.shippingstatus);
    });

  const canRecreateConsignment = () => {
    // TEMPORARY AUGC only
    const hasAUGC = products.some((product) => product.warehouse === "AUGC");

    const hasUSTXorAUGC = products.some(
      (product) => product.warehouse === "USTX" || product.warehouse === "AUGC"
    );

    // if there's a CMDH in TX, the whole order will go via P1 manually, no auto consignment with FedEx
    const hasNoCMDHinTX = products.every(
      (product) =>
        !(product.pid.startsWith("CMDH") && product.warehouse === "USTX")
    );

    const hasValidStatus = products.some((product) =>
      [
        "ORDER_CREATED",
        "PROCESSING_ORDER",
        "HOLD_SHIPMENT",
        "READY_FOR_SHIPMENT",
      ].includes(product.shippingstatus)
    );

    return hasAUGC && hasUSTXorAUGC && hasNoCMDHinTX && hasValidStatus;
  };
  const orderCanHaveConsignmentRecreated = canRecreateConsignment();

  const canAddFreeProduct = () => {
    const hasValidStatus = products.some((product) =>
      [
        "ORDER_CREATED",
        "PROCESSING_ORDER",
        "HOLD_SHIPMENT",
        "READY_FOR_SHIPMENT",
      ].includes(product.shippingstatus)
    );

    let isEnabled = false; // todo re-enable once tested
    return hasValidStatus && isEnabled;
  };
  const orderCanHaveFreeProductAdded = canAddFreeProduct();

  const isMenuItemAvailable =
    permissions.resendOrderConfirmation ||
    permissions.cancelOrder ||
    (permissions.scheduleItem && canShipItems) ||
    (permissions.shipItem && canShipItems) ||
    (permissions.holdOrder && canShipItems) ||
    (permissions.markAsDelivered && canMarkAsDelivered) ||
    permissions.createCustomerTicket ||
    permissions.recreateConsignment ||
    permissions.addFreeProductToOrder;

  return (
    <>
      {isMenuItemAvailable && (
        <Button
          data-testid="actions-button"
          aria-controls="order-actions-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            minWidth: "200px",
            px: 2,
            height: "40px",
          }}
          size="large"
          variant={"contained"}
          color={"primary"}
        >
          Order Actions
        </Button>
      )}
      <Menu
        id="order-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose()}
      >
        {permissions.addFreeProductToOrder && orderCanHaveFreeProductAdded && (
          <MenuItem onClick={handleClose("addFreeProductToOrder")}>
            Add Free Product To Order
          </MenuItem>
        )}
        {permissions.cancelOrder && (
          <MenuItem onClick={handleClose("cancelOrder")}>Cancel Order</MenuItem>
        )}
        {permissions.createCustomerTicket && (
          <MenuItem onClick={handleClose("createCustomerTicket")}>
            Create Customer Ticket
          </MenuItem>
        )}
        {canShipItems && permissions.holdOrder && (
          <MenuItem onClick={handleClose("holdOrder")}>Hold Order</MenuItem>
        )}
        {permissions.markAsDelivered && canMarkAsDelivered && (
          <MenuItem onClick={handleClose("markAsDelivered")}>
            Mark as Delivered
          </MenuItem>
        )}
        {permissions.recreateConsignment &&
          orderCanHaveConsignmentRecreated && (
            <MenuItem onClick={handleClose("recreateConsignment")}>
              Recreate Carrier Consignment
            </MenuItem>
          )}
        {permissions.resendOrderConfirmation && (
          <MenuItem onClick={handleClose("resendOrderConfirmation")}>
            Resend Order Confirmation
          </MenuItem>
        )}
        {permissions.scheduleItem && canShipItems && (
          <MenuItem onClick={handleClose("scheduleShipment")}>
            Schedule Shipment
          </MenuItem>
        )}
        {permissions.shipItem && canShipItems && (
          <MenuItem onClick={handleClose("shipItems")}>Ship Items</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default OrderActionsMenu;
