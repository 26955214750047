import { useCallback, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "axios";
import {
  batchTwoLoadingState,
  customerOrdersState,
  permissionsState,
} from "../atoms/atoms";
import {
  customerOrdersFirstBatchLength,
  loadFilter,
  loadSearchTerm,
} from "../common";
import { buildQueryString } from "../utils/filterUtils";
import { getOrders } from "../api/CustomerOrders";
import { getFilter } from "../api/Filters";

function validateDisplayableOrder(order, permissions) {
  if (
    permissions.name !== "Super Admin" &&
    !permissions.customerOrders.actions?.viewHiddenOrders
  ) {
    return !(
      order["orderpaymentstatus"] === "NOT_PAID" || order["canceled"] === true
    );
  }
  return true;
}

const useCustomerOrders = () => {
  const [, setCustomerOrders] = useRecoilState(customerOrdersState);
  const permissions = useRecoilValue(permissionsState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const loadingBatchRef = useRef(false);
  const cancelTokenRef = useRef(null);

  const previousFilterRef = useRef(loadFilter("customerOrders"));
  const previousSearchRef = useRef(loadSearchTerm("customerOrders"));
  const [filterId, setFilterId] = useState(previousFilterRef.current);
  const [searchTerm, setSearchTerm] = useState(previousSearchRef.current);

  const [, setBatchTwoLoading] = useRecoilState(batchTwoLoadingState);

  const loadFirstBatch = async (filterDetails, currentSearchTerm, andOr) => {
    const fetchQuery = buildQueryString(
      currentSearchTerm,
      filterDetails,
      andOr,
      customerOrdersFirstBatchLength,
      0
    );

    const response = await getOrders(fetchQuery);
    const orders =
      response?.results?.filter((order) =>
        validateDisplayableOrder(order, permissions)
      ) || [];

    setCustomerOrders(orders);
    setPage(0);

    return { orders };
  };

  const loadSecondBatch = async (filterDetails, currentSearchTerm, andOr) => {
    if (loadingBatchRef.current) {
      // Cancel previous request if it exists
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Operation canceled due to new request");
      }
      return;
    }

    setBatchTwoLoading(true);
    loadingBatchRef.current = true;
    cancelTokenRef.current = axios.CancelToken.source();

    try {
      const fetchQuery = buildQueryString(
        currentSearchTerm,
        filterDetails,
        andOr,
        null, // Load all remaining
        customerOrdersFirstBatchLength // Start from where first batch ended
      );

      const response = await getOrders(fetchQuery, {
        cancelToken: cancelTokenRef.current.token,
      });

      const newOrders =
        response?.results?.filter((order) =>
          validateDisplayableOrder(order, permissions)
        ) || [];

      setCustomerOrders((prevOrders) => {
        const existingIds = new Set(prevOrders.map((order) => order.id));
        const uniqueNewOrders = newOrders.filter(
          (order) => !existingIds.has(order.id)
        );
        return [...prevOrders, ...uniqueNewOrders];
      });

      setPage(1);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Error loading second batch:", error);
      }
    } finally {
      loadingBatchRef.current = false;
      cancelTokenRef.current = null;
      setBatchTwoLoading(false);
    }
  };

  const loadOrders = useCallback(
    async (newPage = 0) => {
      try {
        setIsLoading(true);
        setErrorMsg("");

        // Cancel any ongoing second batch load
        if (cancelTokenRef.current) {
          cancelTokenRef.current.cancel(
            "Operation canceled due to new request"
          );
          cancelTokenRef.current = null;
        }
        loadingBatchRef.current = false;

        const currentFilterId = loadFilter("customerOrders");
        const currentSearchTerm = loadSearchTerm("customerOrders");
        const hasFilters = !!currentFilterId || !!currentSearchTerm;

        const filterDetails = currentFilterId
          ? (await getFilter(currentFilterId)).filter
          : null;
        const andOr = filterDetails?.andOr || "and";

        if (hasFilters) {
          // First batch - quick load
          await loadFirstBatch(filterDetails, currentSearchTerm, andOr);

          // Immediately start loading second batch
          loadSecondBatch(filterDetails, currentSearchTerm, andOr);
        } else {
          // Regular infinite loading without filters
          const fetchQuery = buildQueryString(
            currentSearchTerm,
            filterDetails,
            andOr,
            customerOrdersFirstBatchLength,
            newPage * customerOrdersFirstBatchLength
          );

          const response = await getOrders(fetchQuery);
          const newOrders =
            response?.results?.filter((order) =>
              validateDisplayableOrder(order, permissions)
            ) || [];

          if (newPage === 0) {
            setCustomerOrders(newOrders);
          } else {
            setCustomerOrders((prevOrders) => {
              const existingIds = new Set(prevOrders.map((order) => order.id));
              const uniqueNewOrders = newOrders.filter(
                (order) => !existingIds.has(order.id)
              );
              return [...prevOrders, ...uniqueNewOrders];
            });
          }

          setPage(newPage);
        }
      } catch (error) {
        console.error("Error loading orders:", error);
        setErrorMsg(
          newPage === 0
            ? "Error loading initial orders. Please try again."
            : "Error loading additional orders. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [setCustomerOrders]
  );

  // Handle filter and search changes
  const handleFilterChange = useCallback((newFilterId) => {
    setFilterId(newFilterId);
  }, []);

  const handleSearchChange = useCallback((newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  }, []);

  return {
    loadOrders,
    ordersLoading: isLoading,
    ordersError: errorMsg,
    setFilterId: handleFilterChange,
    setSearchTerm: handleSearchChange,
    page,
    totalRows,
    hasFilter: !!filterId || !!searchTerm,
  };
};

export default useCustomerOrders;
