import {
  gridClasses,
  GridFooterContainer,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useRecoilValue } from "recoil";
import { batchTwoLoadingState } from "../../atoms/atoms";
import CircularProgress from "@mui/material/CircularProgress";

export const CustomFooter = () => {
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();
  const isBatchTwoLoading = useRecoilValue(batchTwoLoadingState);

  return (
    <GridFooterContainer
      sx={{
        minHeight: "20px",
        alignItems: "center",
        "& .MuiTablePagination-root": {
          height: "100%",
        },
      }}
      className={gridClasses.footerContainer}
    >
      <Box sx={{ display: { xs: "none", sm: "flex" } }} flex={1}></Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {isBatchTwoLoading && (
          <CircularProgress
            size={12}
            thickness={4}
            sx={{ color: "text.secondary" }}
          />
        )}
        <Typography
          sx={{
            marginRight: 1,
            fontSize: 12,
          }}
        >
          Total Rows: {rowCount}
        </Typography>
      </Box>
    </GridFooterContainer>
  );
};
