import pusher from "../pusherClient";
import { decrypt, decryptObject } from "../../../utils/encryption";

export const subscribeToOrderChannel = (
  orderID,
  userID,
  setConversations,
  setUpdatedConversations,
  setMessagesByConversation,
  messagesByConversation,
  activeConversation,
  updateMessages,
  setFiles
) => {
  const orderChannel = pusher.subscribe(`order-${orderID}`);

  orderChannel.bind("update-conversation", async (data) => {
    const { updatedConversation } = await decrypt(data);

    setConversations((prevState) =>
      prevState.map((conversation) =>
        conversation.id === updatedConversation.id
          ? updatedConversation
          : conversation
      )
    );
  });

  orderChannel.bind("new-conversation", async (data) => {
    const { conversation, createdBy } = await decrypt(data);

    if (createdBy !== userID)
      setConversations((prevState) => [...prevState, conversation]);
  });

  orderChannel.bind("new-message", async (data) => {
    const message = await decrypt(data.message);

    const updatedMessage = { ...message };
    if (activeConversation === message.conversationID)
      updatedMessage.read = [...updatedMessage.read, userID];

    setUpdatedConversations(updatedMessage);
    const conversationID = updatedMessage.conversationID;

    if (message.files)
      setFiles((prevFiles) => [
        ...(prevFiles || []),
        ...message.files.map((file) => ({
          name: file.name,
          type: file.type,
          url: file.url,
          size: file.size,
          id: file.id,
        })),
      ]);

    if (
      conversationID in messagesByConversation &&
      activeConversation !== message.conversationID &&
      !messagesByConversation[conversationID]?.find(
        (msg) => msg.id === updatedMessage.id
      )
    ) {
      setMessagesByConversation((prevState) => ({
        ...prevState,
        [conversationID]: [...prevState[conversationID], updatedMessage],
      }));
    }

    if (activeConversation === message.conversationID)
      updateMessages([message.id], "markRead")
        .then((rsp) => {
          // console.log(rsp);
        })
        .catch((err) => {
          console.log(err);
        });
  });

  return () => {
    orderChannel.unbind_all();
    orderChannel.unsubscribe();
  };
};
