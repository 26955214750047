import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DatePicker from "../../../components/DatePicker";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import DialogContentText from "@mui/material/DialogContentText";
import Table from "../../../components/Table";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import {useDateFormat} from "../../../contexts/DateFormatContext";
import {formatDate} from "../../../utils/dateFormatter";

const HoldOrderContent = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);
  const [localSelection, setLocalSelection] = useState([]);

  useEffect(() => {
    const wixOrderNumber = data.orders[0]?.wixordernumber;

    const firstId = data.orders[0]?.id;
    const firstStatus = data.orders[0]?.shippingstatus;
    const allSameStatus = data.orders.every(
      (order) => order.shippingstatus === firstStatus
    );

    // If all orders have the same status, select all of them initially
    const initialSelection = allSameStatus
      ? data.orders.map((order) => order.id)
      : [];

    setState({
      date: dayjs().add(1, "day"),
      selectionModel: initialSelection,
      wixOrderNumber: wixOrderNumber,
      isHeldItemSelected: firstStatus === "HOLD_SHIPMENT" && initialSelection[0] === firstId,
    });
  }, [setState, data.orders]);

  useEffect(() => {
    setLocalSelection(state?.selectionModel || []);
  }, [state?.selectionModel]);

  const handleDateChange = (date) => {
    setState((prevState) => ({ ...prevState, date: dayjs(date) }));
  };

  const handleSelectionChange = (isSelected, field, id) => {
    if (field !== "selection") return;
    const selectedOrder = data.orders.find((order) => order.id === id);
    const isHeldItemSelected = selectedOrder.shippingstatus === "HOLD_SHIPMENT";

    const updateSelectionModel = isSelected
      ? [...(state.selectionModel || []), id]
      : state.selectionModel.filter((itemId) => itemId !== id);

    setLocalSelection(updateSelectionModel);
    setState((prevState) => ({
      ...prevState,
      selectionModel: updateSelectionModel,
      isHeldItemSelected: isHeldItemSelected,
    }));
  };

  const { dateFormat } = useDateFormat();

  const columns = [
    {
      headerName: "",
      field: "selection",
      width: 70,
      editable: true,
      renderEditCell: (params) => {
        const isChecked = localSelection.includes(params.row.id);
        const isDisabled =
          localSelection.length > 0 &&
          ((state.isHeldItemSelected &&
            params.row.shippingstatus !== "HOLD_SHIPMENT") ||
            (!state.isHeldItemSelected &&
              params.row.shippingstatus === "HOLD_SHIPMENT" &&
              !isChecked));

        return (
          <Checkbox
            checked={isChecked}
            onChange={(event) =>
              handleSelectionChange(
                event.target.checked,
                "selection",
                params.row.id
              )
            }
            disabled={isDisabled}
          />
        );
      },
    },
    { field: "uid", headerName: "UID", flex: 1 },
    { field: "descriptionshort", headerName: "Description", flex: 2 },
    { field: "shippingstatus", headerName: "Status", flex: 1 },
    {
      field: "holduntildate",
      headerName: "Hold til",
      width: 90,
      flex: 1,
      renderCell: (params) => {
        let date = params.row?.shippingstatus === "HOLD_SHIPMENT" && params.row?.holduntildate;
        const dateString = date ? formatDate(date, dateFormat) : ""
        return dateString;
      },
    },
  ];

  return (
    <Box>
      <DialogContentText sx={{ mb: 3 }}>
        {state.isHeldItemSelected
          ? "Select items to release from hold:"
          : "Select items to place on hold and set a hold date:"}
      </DialogContentText>
      <Box width={"100%"} mt={2} mb={3}>
        <Table
          key={localSelection.join(",")}
          columns={columns}
          rows={data.orders || []}
          onCellChange={handleSelectionChange}
        />
      </Box>
      <DatePicker
        label={"Hold Until Date"}
        value={state.date}
        onChange={handleDateChange}
        minDate={dayjs().add(1, "day")}
      />
    </Box>
  );
};

export default HoldOrderContent;
