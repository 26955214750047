import React, { useCallback, useState } from "react";
import Modal from "../../../components/Modal";
import { setWarehouseStockOverride } from "../../../api/InventoryOrders";
import DatePicker from "../../../components/DatePicker";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";

const AllowOrdersWhenOutOfStock = ({
  open,
  handleClose,
  selectedItem,
  handleOrdersChange,
}) => {
  const [formState, setFormState] = useState({
    endHoldDate: dayjs(),
    allowOrdersWhenOOS: true,
    holdOrders: true,
    loading: false,
    error: null,
  });

  // Reset form when modal opens
  React.useEffect(() => {
    if (open) {
      setFormState((prev) => ({
        ...prev,
        endHoldDate: dayjs(),
        allowOrdersWhenOOS: selectedItem.alloworderswhenoos,
        holdOrders: selectedItem.alloworderswhenoos,
        error: null,
        loading: false,
      }));
    }
  }, [open]);

  const handleDateChange = useCallback((date) => {
    setFormState((prev) => ({
      ...prev,
      endHoldDate: date,
      error: null,
    }));
  }, []);

  const handleCheckboxChange = useCallback(
    (field) => (event) => {
      setFormState((prev) => ({
        ...prev,
        [field]: event.target.checked,
        holdOrders:
          // If unchecking "Allow Orders When OOS", also uncheck "Hold Orders"
          field === "allowOrdersWhenOOS" && !event.target.checked
            ? false
            : field === "holdOrders"
            ? event.target.checked
            : prev.holdOrders,
        error: null,
      }));
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    // Validation logic
    if (
      !formState.allowOrdersWhenOOS &&
      formState.holdOrders &&
      !formState.endHoldDate
    ) {
      setFormState((prev) => ({
        ...prev,
        error: "Please select an end hold date. ",
      }));
      return;
    }

    setFormState((prev) => ({ ...prev, loading: true, error: null }));

    try {
      await setWarehouseStockOverride(
        selectedItem.sid,
        formState.allowOrdersWhenOOS,
        formState.holdOrders ? formState.endHoldDate : null
      );
      handleOrdersChange(
        selectedItem.id,
        "alloworderswhenoos",
        formState.allowOrdersWhenOOS
      );

      handleClose();
    } catch (err) {
      console.error(err);
      setFormState((prev) => ({
        ...prev,
        error:
          "Failed to update warehouse stock override. Please try again later. ",
        loading: false,
      }));
    }
  }, [formState, selectedItem, handleOrdersChange, handleClose]);

  const handleCancel = useCallback(() => {
    handleClose();
    setTimeout(() => {
      setFormState((prev) => ({
        ...prev,
        endHoldDate: dayjs(),
        allowOrdersWhenOOS: true,
        holdOrders: true,
        error: null,
      }));
    }, 150);
  }, [handleClose]);

  return (
    <Modal
      title="Hold / Out of Stock"
      openModal={open}
      handleCloseModal={handleCancel}
      maxWidth="sm"
      errorMsg={formState.error}
      actions={
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mx: 2,
          }}
        >
          <Button
            sx={{ width: { xs: "120px", sm: "180px" } }}
            variant="outlined"
            color="error"
            onClick={handleCancel}
            disabled={formState.loading}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ borderRadius: "20px", width: { xs: "120px", sm: "180px" } }}
            loading={formState.loading}
            variant="outlined"
            onClick={handleSubmit}
            disabled={formState.holdOrders && !formState.endHoldDate}
            color="primary"
          >
            Confirm
          </LoadingButton>
        </Box>
      }
    >
      <Stack spacing={2} sx={{ mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.allowOrdersWhenOOS}
              onChange={handleCheckboxChange("allowOrdersWhenOOS")}
            />
          }
          label="Allow Orders When Out of Stock"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.holdOrders}
              onChange={handleCheckboxChange("holdOrders")}
            />
          }
          label="Hold Orders"
        />
        {formState.holdOrders && (
          <Box sx={{ ml: "8px !important", mt: "24px !important" }}>
            <DatePicker
              label="End Hold Date"
              value={formState.endHoldDate}
              onChange={handleDateChange}
              required
              error={Boolean(formState.error)}
              helperText={formState.error}
              startingTimezone={selectedItem?.warehouse?.slice(0, 2)}
            />
          </Box>
        )}
      </Stack>
    </Modal>
  );
};

export default AllowOrdersWhenOutOfStock;
