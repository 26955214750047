import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import React, {useEffect} from "react";

export const SearchFieldAutocomplete = ({
  label,
  selectedFields,
  searchFields,
  onUpdate,
  multiple,
  autoFocus,
}) => {

  let randomUnique = Math.random().toString().slice(-6); // digits or .
  let focusFieldId = "sFieldAutoTextInput-" + label?.replace(/[^a-zA-Z]/g, '') + "-" + randomUnique

  useEffect(() => {
    if (autoFocus) {
      // Focus the autocomplete input directly by querying the DOM - might be better to do this via a ref instead?
      const autocompleteInput = document.getElementById(focusFieldId);
      if (autocompleteInput) {
        autocompleteInput.focus();
      }
    }
  }, [autoFocus]);
  
  return (
    <Autocomplete
      multiple={multiple}
      options={searchFields}
      value={selectedFields}
      disablePortal={false}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            id: focusFieldId, // force a custom ID
          }}
          label={label}
          sx={{
            ".MuiInputBase-root": { borderRadius: "20px" },
          }}
        />
      )}
      onChange={(e, values) => {
        onUpdate(values);
      }}
      isOptionEqualToValue={(option, value) => {
        if (!value?.label) return option?.label === value;
        return option?.label === value?.label;
      }}
    />
  );
};
