import CryptoJS from "crypto-js";
import { MESSAGE_ENCRYPTION_KEY } from "../consts";

// Ensure the key is properly formatted for CryptoJS
const key = CryptoJS.enc.Base64.parse(MESSAGE_ENCRYPTION_KEY);

export const decrypt = (encryptedData) => {
  if (!encryptedData?.startsWith("aes-256-cbc")) {
    console.warn("decrypt() input data does not include algo, skipping decryption")
    return encryptedData
  }
  try {
    const [algo, ivHex, ciphertext] = encryptedData.split(":");

    if (!algo || !ivHex || !ciphertext) {
      throw new Error("Invalid encrypted data format");
    }

    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encrypted = CryptoJS.enc.Hex.parse(ciphertext);

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: encrypted }, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText);
  } catch (error) {
    console.error("Decryption error:", error, "Encrypted data:", encryptedData);
    throw new Error("Failed to decrypt data");
  }
};

// Optional: If you need encryption on the frontend as well
export const encrypt = (data) => {
  const algo = "aes-256-cbc"
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return (
    algo + ":" +
    iv.toString(CryptoJS.enc.Hex) + ":" +
    encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  );
};
