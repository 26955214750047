import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useMediaQuery, useTheme } from "@mui/material";
import NormalTable from "./NormalTable";
import TransposedTable from "./TransposedTable";
import TimezoneSelector from "./TimezoneSelector";
import { updateRowDates } from "./utils";
import { officeTimezoneMap } from "./constants";
import CustomTablePagination from "./CustomTablePagination";

const ReusableTable = ({
  columns,
  rows,
  onCellChange,
  handleAdd,
  handleDelete,
  globalTimezone: initialGlobalTimezone = "AU",
  pagination = false,
  rowsPerPage: initialRowsPerPage = 10,
  maxHeight,
  allowBulkEdit = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const shouldTranspose = rows?.length < 4 && (isMobile || columns?.length > 7);
  const [globalTimezone, setGlobalTimezone] = useState(initialGlobalTimezone);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedRows = useMemo(() => rows, [rows]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [selectedRows, setSelectedRows] = useState([]);

  const selectedRowsRef = useRef(selectedRows);

  useEffect(() => {
    selectedRowsRef.current = selectedRows;
  }, [selectedRows]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      onCellChange(value, field, id);
    },
    [onCellChange]
  );

  useEffect(() => {
    setGlobalTimezone(initialGlobalTimezone);
  }, [initialGlobalTimezone]);

  const handleGlobalTimezoneChange = (event) => {
    const newTimezone = event.target.value;
    setGlobalTimezone(newTimezone);

    const updatedRows = rows.map((row) =>
      updateRowDates(row, columns, newTimezone)
    );

    updatedRows.forEach((row) => {
      columns.forEach((column) => {
        if (column.type === "date") {
          onCellChange(row[column.field], column.field, row.id);
        }
      });
    });
  };

  const hasDatePicker = useMemo(() => {
    return columns.some((column) => column.type === "date");
  }, [columns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = useMemo(() => {
    if (!pagination) return memoizedRows;
    const startIndex = page * rowsPerPage;
    return memoizedRows.slice(startIndex, startIndex + rowsPerPage);
  }, [memoizedRows, page, rowsPerPage, pagination]);

  const handleRowSelect = useCallback((rowId, isSelected) => {
    setSelectedRows((prev) =>
      isSelected ? [...prev, rowId] : prev.filter((id) => id !== rowId)
    );
  }, []);

  const handleSelectAll = useCallback(
    (isSelected) => {
      setSelectedRows(isSelected ? paginatedRows.map((row) => row.id) : []);
    },
    [paginatedRows]
  );

  const handleBulkEdit = useCallback(
    (field, value) => {
      selectedRowsRef.current.forEach((rowId) => {
        onCellChange(value, field, rowId);
      });
    },
    [onCellChange]
  );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        mt: { xs: hasDatePicker ? 4 : 0, sm: 0 },
      }}
    >
      {hasDatePicker && (
        <TimezoneSelector
          globalTimezone={globalTimezone}
          handleGlobalTimezoneChange={handleGlobalTimezoneChange}
          officeTimezoneMap={officeTimezoneMap}
        />
      )}

      <Paper
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {shouldTranspose ? (
            <TransposedTable
              columns={memoizedColumns}
              rows={paginatedRows}
              handleInputChange={handleInputChange}
              handleDelete={handleDelete}
              globalTimezone={globalTimezone}
              maxHeight={maxHeight}
              allowBulkEdit={allowBulkEdit}
              selectedRows={selectedRows}
              onRowSelect={handleRowSelect}
              onSelectAll={handleSelectAll}
              onBulkEdit={handleBulkEdit}
            />
          ) : (
            <NormalTable
              columns={memoizedColumns}
              rows={paginatedRows}
              handleInputChange={handleInputChange}
              handleAdd={handleAdd}
              handleDelete={handleDelete}
              globalTimezone={globalTimezone}
              maxHeight={maxHeight}
              allowBulkEdit={allowBulkEdit}
              selectedRows={selectedRows}
              onRowSelect={handleRowSelect}
              onSelectAll={handleSelectAll}
              onBulkEdit={handleBulkEdit}
            />
          )}
        </Box>
        {shouldTranspose && handleAdd && (
          <Box display="flex" justifyContent="center" mt={2}>
            <IconButton onClick={handleAdd} size="large">
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </Box>
        )}
      </Paper>
      {pagination && (
        <CustomTablePagination
          count={rows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

ReusableTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      width: PropTypes.number,
      maxWidth: PropTypes.number,
      editable: PropTypes.bool,
      type: PropTypes.string,
      renderEditCell: PropTypes.func,
      renderCell: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCellChange: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  globalTimezone: PropTypes.string,
  pagination: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allowBulkEdit: PropTypes.bool,
};

export default ReusableTable;
