// components/RolesList.js
import React from "react";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { motion } from "framer-motion";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ComputerIcon from "@mui/icons-material/Computer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import StorefrontIcon from "@mui/icons-material/Storefront";

import RoleActionMenu from "./RoleActionMenu";

const IconMap = {
  Manager: <BusinessCenterIcon fontSize="large" />,
  Developer: <ComputerIcon fontSize="large" />,
  Support: <SupportAgentIcon fontSize="large" />,
  Engineer: <EngineeringIcon fontSize="large" />,
  Employee: <AccountCircleIcon fontSize="large" />,
  Logistics: <LocalShippingIcon fontSize="large" />,
  Maintenance: <CleaningServicesIcon fontSize="large" />,
  Retail: <StorefrontIcon fontSize="large" />,
};

const RolesList = ({
  roles,
  permissionsNumbers,
  permissionUserNamesMap,
  onEditRole,
  onDeleteRole,
  onDuplicateRole,
}) => {
  // Sort roles alphabetically by name
  const sortedRoles = [...roles].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <List
      sx={{
        borderTop: 1,
        borderColor: "divider",
        p: 2,
        pt: 2,
        maxHeight: { xs: "calc(100vh - 340px)", sm: "calc(100vh - 300px)" },
        overflow: "auto",
      }}
    >
      {sortedRoles.map((role) => {
        const userNames = permissionUserNamesMap[role.id] || "-";
        const memberCount = permissionsNumbers[role.id] || 0;
        const memberCountText =
          memberCount === 1 ? "(1) member" : `(${memberCount}) members`;

        return (
          <motion.div
            key={role.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ListItem
              sx={{
                "&:hover": { opacity: "0.9" },
                p: 1,
                pl: 2,
                marginBottom: "1rem",
                borderRadius: "8px",
                minHeight: "90px",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              }}
            >
              {role?.icon && (
                <Box ml={{ xs: 0, sm: 1 }} mr={3}>
                  {IconMap[role.icon]}
                </Box>
              )}
              <ListItemText
                sx={{
                  width: "100%",
                  mr: { xs: "0 !important", sm: 10 },
                  overflow: "hidden",
                  ".MuiListItemText-primary": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: { xs: "100%", sm: "90%", md: "90%" },
                  },
                  ".MuiListItemText-secondary": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: { xs: "100%", sm: "80%", md: "80%" },
                  },
                }}
                primary={role.name}
                secondary={
                  <Typography
                    noWrap={false}
                    display="-webkit-box"
                    style={{
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      color: "rgba(0, 0, 0, 0.6)",
                    }}
                  >
                    {role.description}
                  </Typography>
                }
              />
              <Typography
                sx={{
                  flexShrink: 0,
                  marginRight: "1rem",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
                title={userNames}
              >
                {memberCountText}
              </Typography>
              <RoleActionMenu
                role={role}
                onEdit={onEditRole}
                onDelete={onDeleteRole}
                onDuplicate={onDuplicateRole}
              />
            </ListItem>
          </motion.div>
        );
      })}
    </List>
  );
};

export default RolesList;
