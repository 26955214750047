import React from "react";
import { Paper, Box, Typography, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import { orderSettingsState } from "../../../../atoms/atoms";
import { useDateFormat } from "../../../../contexts/DateFormatContext";
import InventoryProductCard from "../productDetailsCard/ProductCard";
import { InventoryOrderField } from "./OrderField";
import ActionsMenu from "../../../InventoryOrders/components/actionsMenu";
import useModalManager from "../../../../hooks/useModalManager";
import DynamicModal from "../../../InventoryOrders/components/DynamicModal";
import { useNavigate } from "react-router-dom";

const InventoryProductDetails = ({ products, handleOrderChange }) => {
  let navigate = useNavigate();
  const { dateFormat } = useDateFormat();
  const orderSettings = useRecoilValue(orderSettingsState);
  const warehouseOrderNumber = products[0]?.warehouseordernumber;
  const { openModal, closeModal, currentModal } = useModalManager();

  const getLongestLabelLength = (columns, level) =>
    columns?.reduce(
      (longest, column) =>
        column.level === level && column?.label?.length > longest
          ? column.label.length
          : longest,
      0
    ) || 0;

  const longestLengthItem = getLongestLabelLength(
    orderSettings?.columnsDisplayed,
    "item"
  );
  const longestLengthOrder = getLongestLabelLength(
    orderSettings?.columnsDisplayed,
    "order"
  );

  const onActionSelect = (action, selectedProducts) => {
    if (action === "newInventoryOrder") {
      localStorage.setItem("selectedWarehouse", products[0].warehouse);
      navigate("/inventoryOrders/buildOrder");
    }
    const data = {
      orders: selectedProducts
        ? [selectedProducts]
        : action === "updateStatus" ||
          action === "distributeOrderCosts" ||
          action === "shipInventory"
        ? [products[0]]
        : products,
    };
    if (action === "editInventoryOrder") {
      navigate(
        `/inventoryOrders/buildOrder?warehouseOrderNumber=${data.orders[0].warehouseordernumber}&&warehouse=${data.orders[0].warehouse}`
      );
    }
    openModal(action, data);
  };

  return (
    <Paper sx={{ p: 3, pt: 1, borderRadius: 2 }}>
      {currentModal && (
        <DynamicModal
          onClose={closeModal}
          currentModal={currentModal}
          refetch={handleOrderChange}
        />
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Grid container spacing={0.5} mt={1} mb={{ xs: 2, sm: 1 }}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h4" fontWeight="bold" mb={1}>
                {warehouseOrderNumber}
              </Typography>
            </Grid>

            {orderSettings?.columnsDisplayed
              ?.filter((field) => field.level === "order")
              ?.map((field) => (
                <InventoryOrderField
                  key={field.fieldName}
                  field={field}
                  product={products[0]}
                  longestLength={longestLengthOrder}
                  dateFormat={dateFormat}
                />
              ))}
          </Grid>

          <Box
            display={{ xs: "flex", sm: "none" }}
            flexDirection="column"
            my={1}
            gap={1}
            width={200}
          >
            <ActionsMenu
              onActionSelect={onActionSelect}
              selectedRows={products}
              currentPage="stockOrders"
              forceGroupActions={true}
            />
          </Box>
        </Box>

        <Box
          display={{ xs: "none", sm: "flex" }}
          flexDirection={{ sm: "column", lg: "row" }}
          sx={{ gap: 2 }}
          alignItems="center"
        >
          <ActionsMenu
            onActionSelect={onActionSelect}
            selectedRows={products}
            currentPage="stockOrders"
            forceGroupActions={true}
          />
        </Box>
      </Box>

      <Box mt={1}>
        {products?.map((product) => (
          <InventoryProductCard
            key={product.id}
            product={product}
            handleActionSelect={(action) => {
              console.log(product, action);
              onActionSelect(action, product);
            }}
            longestLabelLength={longestLengthItem}
          />
        ))}
      </Box>
    </Paper>
  );
};

export default InventoryProductDetails;
