import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { modalState } from "../../../atoms/atoms";
import Table from "../../../components/Table";
import { useRecoilState } from "recoil";
import SerialNumberInput from "../components/SerialNumberInput";

const AddMachineSpecs = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders.map((order) => ({
        ...order,
        serialnumbers: order.serialnumbers
          ? order.serialnumbers.split("，")
          : null,
      })),
    });
  }, [data.orders, setState]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rownumber === id ? { ...row, [field]: value } : row
        ),
      }));
    },
    [setState]
  );

  const columns = [
    {
      field: "warehouseordernumber",
      headerName: "Warehouse Order Number",
      width: 100,
    },
    {
      field: "rownumber",
      headerName: "Row Number",
      width: 150,
    },
    {
      field: "controlboardpanelmodel",
      headerName: "Control Board Panel Model",
      width: 200,
      editable: true,
    },
    {
      field: "serialnumbers",
      headerName: "Serial Numbers",
      width: 400,
      editable: true,
      renderEditCell: (params) => (
        <SerialNumberInput
          value={params.row.serialnumbers || []}
          onChange={(value) =>
            handleInputChange(value, "serialnumbers", params.row.rownumber)
          }
          rowId={params.row.rownumber}
          maxQuantity={params.row.quantityordered} // Add this prop to show validation in the component
        />
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="body1" color="text.secondary">
        Add machine specifications for the inventory items.
      </Typography>

      <Table
        columns={columns}
        rows={state.rows}
        onCellChange={handleInputChange}
        getRowId={(row) => row.rownumber}
      />
    </Box>
  );
};

export default AddMachineSpecs;
