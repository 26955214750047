import React from "react";
import { Box, Button, Grid, Paper } from "@mui/material";
import FreightFiller from "../../../components/FreightFiller";
import { inventorySuppliers } from "../../../common";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "../../../components/DatePicker";
import Table from "../../../components/Table";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";
import SerialNumberInput from "../../InventoryOrders/components/SerialNumberInput";
import { getImage } from "../../../components/Datagrid/utils";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const ReviewOrderScreen = ({
  orderData,
  onBack,
  onSubmit,
  handleProductsChange,
  warehouseProducts,
  loading,
  error,
  hideFreightFiller,
}) => {
  const { products, notes, dateOrdered, containerSize, isAContainerOrder } =
    orderData;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const warehouseOrderNumber = searchParams.get("warehouseOrderNumber");
  const handleChange = (value, field, id) => {
    handleProductsChange(value, field, id);
  };

  const editModeColumns = [
    {
      field: "rownumber",
      headerName: "Row Number",
    },
    {
      field: "inventoryitem",
      headerName: "Inventory Item",
    },
    {
      field: "pid",
      headerName: "PID",
    },
    {
      field: "qty",
      headerName: "Quantity Ordered",
    },

    {
      field: "controlboardpanelmodel",
      headerName: "Control Board Panel Model",
      width: 300,
      editable: true,
    },
    {
      field: "serialnumbers",
      headerName: "Serial Numbers",
      width: 400,
      editable: true,
      renderEditCell: (params) => (
        <SerialNumberInput
          value={params.row?.serialnumbers || []}
          onChange={handleChange}
          rowId={params.row.id}
          maxQuantity={params.row.quantityordered}
        />
      ),
    },

    {
      field: "notes",
      headerName: "Notes",
      width: 400,
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => handleChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },

    {
      field: "supplier",
      headerName: "Supplier",
      width: 200,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          sx={{ width: { xs: "100%", sm: "100%" } }}
          value={row.supplier || null}
          onChange={(event, newValue) =>
            handleChange(newValue, "supplier", row.id)
          }
          options={inventorySuppliers}
          renderInput={(params) => (
            <TextField label="Supplier" fullWidth {...params} />
          )}
          isOptionEqualToValue={(option, value) =>
            option === value || value === ""
          }
        />
      ),
    },

    {
      field: "tradefinanceorder",
      headerName: "Trade Finance Order",
      width: 100,
      editable: true,
      type: "boolean",
    },
  ];

  const defaultColumns = [
    {
      field: "image",
      headerName: "Product Image",
      renderCell: (params) => (
        <img
          style={{ width: 100 }}
          alt={params.row.productimage}
          src={getImage(params.row.productimage)}
        />
      ),
    },
    { field: "inventoryitem", headerName: "Product Name" },
    { field: "pid", headerName: "PID", width: 300 },
    {
      field: "qty",
      headerName: "Quantity",
      type: "number",
      width: 200,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      editable: true,
      width: 300,

      renderEditCell: ({ row, onValueChange }) => (
        <Autocomplete
          value={row.supplier || null}
          onChange={(event, newValue) =>
            onValueChange(newValue, "supplier", row.id)
          }
          options={inventorySuppliers}
          renderInput={(params) => (
            <TextField label="Supplier" fullWidth {...params} />
          )}
          isOptionEqualToValue={(option, value) =>
            option === value || value === ""
          }
        />
      ),
    },
    {
      field: "tradefinanceorder",
      headerName: "Trade Finance",
      type: "boolean",
      editable: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      renderEditCell: ({ row, onValueChange }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => onValueChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },
  ];

  const columns = warehouseOrderNumber ? editModeColumns : defaultColumns;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Paper sx={{ p: 2, m: 1, mb: 0 }}>
        <Grid container gap={2}>
          {
            <Grid xs={12} sm={12} md={4} item>
              <Box display={"flex"} gap={2} mb={2} width={"100%"}>
                <Box
                  display={"flex"}
                  flexDirection={{ xs: "column", sm: "row" }}
                  gap={{ xs: 2, sm: 2 }}
                  mt={{ xs: 0, sm: 0 }}
                >
                  <Box sx={{ minWidth: { xs: 200, sm: 280 } }}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      onChange={(date) => handleChange(date, "dateOrdered")}
                      value={dateOrdered}
                      label="Date Ordered"
                    />
                  </Box>
                </Box>
              </Box>

              <TextField
                multiline
                rows={3}
                label="Order Notes"
                value={notes}
                onChange={(e) => handleChange(e.target.value, "notes")}
                fullWidth
              />
            </Grid>
          }
          {!hideFreightFiller && (
            <Grid xs={12} sm={12} md={7.5} item sx={{ alignContent: "center" }}>
              <Box
                display={"flex"}
                justifyContent={{ xs: "none", sm: "none", md: "flex-end" }}
                height={80}
              >
                <FreightFiller
                  volumeFieldName={"cubicVolume"}
                  quantitiyFieldName={"qty"}
                  containerType={
                    containerSize === "allNeeded" || containerSize === "blank"
                      ? 40
                      : containerSize
                  }
                  products={warehouseProducts}
                  rows={orderData.products}
                />
              </Box>
            </Grid>
          )}
          {
            <Grid xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAContainerOrder}
                    onChange={(e) =>
                      handleChange(e.target.checked, "isAContainerOrder")
                    }
                    size="small"
                  />
                }
                label="Is a Container Order"
                sx={{ mb: 0 }}
              />
            </Grid>
          }
        </Grid>
      </Paper>

      <Box
        sx={{
          m: 1,
          mt: 0,
          pt: {
            xs: 1,
            sm: 1,
          },

          height: {
            xs: `calc(100vh - ${
              hideFreightFiller ? (error ? 370 : 340) : error ? 480 : 450
            }px)`,
            sm: `calc(100vh - ${
              hideFreightFiller ? (error ? 370 : 340) : error ? 480 : 435
            }px)`,
            md: `calc(100vh - ${error ? 385 : 340}px)`,
          },
        }}
      >
        <Table
          columns={columns}
          rows={products}
          onCellChange={handleProductsChange}
          allowBulkEdit
        />
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: { xs: 10, sm: 20 },
          right: { xs: 5, sm: 10 },
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          mt: 2,
        }}
      >
        <Button
          onClick={onBack}
          variant="outlined"
          color="primary"
          sx={{
            backgroundColor: "white",
            opacity: 1,

            "&:hover": {
              backgroundColor: "white",
              opacity: 1,
            },
          }}
        >
          Back to Build
        </Button>
        <LoadingButton
          loading={loading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          sx={{
            opacity: 1,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.dark,
              color: "white",
              opacity: 1,
            },
          }}
        >
          {warehouseOrderNumber ? "Update Order" : "Submit Order"}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ReviewOrderScreen;
