import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRecoilValue } from "recoil";
import { permissionsState } from "../../../atoms/atoms";

const ItemActionsMenu = ({ onActionSelect, product }) => {
  const permissions = useRecoilValue(permissionsState).customerOrders.actions;
  const afterShipped = new Set([
    "SHIPPED",
    "SYS_ARRIVED_AT_CUSTOMS",
    "SYS_CUSTOMS_DELAY",
    "SYS_CUSTOMS_CLEARED",
    "SYS_TRANSIT_DELAY",
    "OUT_FOR_DELIVERY",
    "AWAITING_COLLECTION",
    "ATTEMPTED_DELIVERY",
    "SYS_SHIPMENT_LOST",
    "DELIVERED",
  ]);
  const beforeShipped = new Set([
    "ORDER_CREATED",
    "PROCESSING_ORDER",
    "READY_FOR_SHIPMENT",
  ]);

  const canMarkAsDelivered = [
    "SYS_ARRIVED_AT_CUSTOMS",
    "SHIPPED",
    "SYS_CUSTOMS_DELAY",
    "SYS_CUSTOMS_CLEARED",
    "SYS_TRANSIT_DELAY",
    "OUT_FOR_DELIVERY",
    "AWAITING_COLLECTION",
    "ATTEMPTED_DELIVERY",
    "SYS_SHIPMENT_LOST",
  ].includes(product.shippingstatus);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => () => {
    setAnchorEl(null);
    if (action) onActionSelect(action, product);
  };

  const isMenuItemAvailable =
    permissions.editOrderNotes ||
    (permissions.scheduleItem && beforeShipped.has(product.shippingstatus)) ||
    (permissions.shipItem && beforeShipped.has(product.shippingstatus)) ||
    (permissions.markAsDelivered && canMarkAsDelivered) ||
    (permissions.editSerialNumber &&
      ["CMDH", "DMDH"].includes(product.pid.split("-")[0])) ||
    permissions.updateStatus ||
    (permissions.swapProduct && beforeShipped.has(product.shippingstatus)) ||
    (permissions.changeWarehouse &&
      beforeShipped.has(product.shippingstatus)) ||
    (permissions.updateShippingAnalytics &&
      afterShipped.has(product.shippingstatus));

  return (
    <>
      {isMenuItemAvailable && (
        <Button
          aria-controls="order-actions-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            minWidth: "120px",
            maxWidth: "120px",
            height: "30px",
            px: 2,
          }}
          size="large"
          variant={"outlined"}
        >
          Actions
        </Button>
      )}
      <Menu
        id="order-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose()}
      >
        {permissions.editSerialNumber &&
          ["CMDH", "DMDH"].includes(product.pid.split("-")[0]) && (
            <MenuItem onClick={handleClose("changeSerialNumber")}>
              Change Serial Number
            </MenuItem>
          )}
        {permissions.editOrderNotes && (
          <MenuItem onClick={handleClose("changeSupplierNotes")}>
            Change Supplier Notes
          </MenuItem>
        )}
        {permissions.changeWarehouse &&
          beforeShipped.has(product.shippingstatus) && (
            <MenuItem onClick={handleClose("changeWarehouse")}>
              Change Warehouse
            </MenuItem>
          )}
        {permissions.updateStatus && (
          <MenuItem onClick={handleClose("forceStatusUpdate")}>
            Force Status Update
          </MenuItem>
        )}
        {permissions.markAsDelivered && canMarkAsDelivered && (
          <MenuItem onClick={handleClose("markAsDelivered")}>
            Mark as Delivered
          </MenuItem>
        )}
        {permissions.scheduleItem &&
          beforeShipped.has(product.shippingstatus) && (
            <MenuItem onClick={handleClose("scheduleShipment")}>
              Schedule Item
            </MenuItem>
          )}
        {permissions.shipItem && beforeShipped.has(product.shippingstatus) && (
          <MenuItem onClick={handleClose("shipItems")}>Ship Item</MenuItem>
        )}
        {permissions.swapProduct &&
          beforeShipped.has(product.shippingstatus) && (
            <MenuItem onClick={handleClose("swapProduct")}>
              Swap Product
            </MenuItem>
          )}
        {permissions.updateShippingAnalytics &&
          afterShipped.has(product.shippingstatus) && (
            <MenuItem onClick={handleClose("updateShippingAnalytics")}>
              Update Shipping Analytics
            </MenuItem>
          )}
      </Menu>
    </>
  );
};

export default ItemActionsMenu;
