import Alert from "@mui/material/Alert";

const Error = ({ errorMessage, sx, severity = true }) => {
  return (
    <Alert
      sx={{
        ...sx,
      }}
      severity={severity ? "error" : "success"}
    >
      {" "}
      {errorMessage}{" "}
    </Alert>
  );
};
export default Error;
