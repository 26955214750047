import React, { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isGroupRow } from "../../../utils/mui";

const ActionsMenu = ({
  onActionSelect,
  selectedRows,
  currentPage,
  forceGroupActions = false,
  isSubItem,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleActionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleClose = (action) => () => {
    setAnchorEl(null);
    if (action) onActionSelect(action);
  };

  const isOrderSelected = useMemo(() => {
    if (forceGroupActions) return true;
    if (!selectedRows || selectedRows.length === 0) return false;
    return isGroupRow(selectedRows[0]);
  }, [selectedRows, forceGroupActions]);

  const groupActions = [
    "editInventoryOrder",
    "shipInventory",
    "updateStatus",
    "distributeOrderCosts",
    "receiveInventory",
    "addCostsByItem",
    "markAsPaid",
  ];
  const rowActions = [
    "editInventoryOrder",
    "receiveInventory",
    "addCostsByItem",
    "addMachineSpecs",
    "addNotes",
  ];

  const renderMenuItems = () => {
    const items = [];

    if (currentPage !== "stockOrders") {
      items.push(
        <MenuItem
          key="newInventoryOrder"
          onClick={handleClose("newInventoryOrder")}
          disableRipple
        >
          New Inventory Order
        </MenuItem>,
        <MenuItem
          key="warehouseTransfer"
          onClick={handleClose("warehouseTransfer")}
          disableRipple
        >
          Warehouse Transfer
        </MenuItem>,
        <MenuItem
          key="createAuditCorrection"
          onClick={handleClose("createAuditCorrection")}
          disableRipple
        >
          Create Audit Correction
        </MenuItem>
      );
    }
    if (selectedRows && selectedRows.length > 0) {
      if (isOrderSelected) {
        // Order-level actions
        groupActions.forEach((action) => {
          items.push(
            <MenuItem key={action} onClick={handleClose(action)} disableRipple>
              {action.charAt(0).toUpperCase() +
                action
                  .slice(1)
                  .replace(/([A-Z])/g, " $1")
                  .trim()}
            </MenuItem>
          );
        });
      } else {
        // Item-level actions
        rowActions.forEach((action) => {
          items.push(
            <MenuItem key={action} onClick={handleClose(action)} disableRipple>
              {action.charAt(0).toUpperCase() +
                action
                  .slice(1)
                  .replace(/([A-Z])/g, " $1")
                  .trim()}
            </MenuItem>
          );
        });
      }
    }

    return items;
  };

  return (
    <>
      {(currentPage !== "stockOrders" ||
        (currentPage === "stockOrders" &&
          selectedRows &&
          selectedRows.length > 0)) && (
        <Button
          size={isSubItem ? "small" : "large"}
          sx={{
            width: isSubItem ? 120 : 150,
            height: isSubItem ? 35 : 40,
            "&:hover": {
              backgroundColor: (theme) =>
                isSubItem ? "" : theme.palette.primary.dark,
              color: isSubItem ? "" : "white",
            },
          }}
          color={"primary"}
          aria-controls="actions-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant={isSubItem ? "outlined" : "contained"}
          disableElevation
          onClick={handleActionsOpen}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
      )}
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleActionsClose}
        MenuListProps={{
          "aria-labelledby": "actions-button",
        }}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
};

export default ActionsMenu;
