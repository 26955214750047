import "./App.css";
import Router from "./Router";
import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";
import initializeConfiguredSentry from "./services/sentry/sentryInit";
import VersionChecker from "./components/VersionChecker";
import React from "react";

initializeConfiguredSentry();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <VersionChecker />
      <Router />
    </ThemeProvider>
  );
}

export default App;
