import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import DataGrid from "../../components/Datagrid/Datagrid";
import Error from "../../components/Error";
import FilterMenu from "../../components/Filter";
import MainContainer from "../../layouts/main";
import CardView from "../../components/CardView";
import CardViewSettingsModal from "../../components/CardViewSettingsModal/Modal";
import Loading from "../../components/Loading";
import SearchOrdersInput from "../../components/SearchOrdersInput";
import ViewToggleButtons from "./components/ViewToggleButtons";
import { ActionMenu } from "./components/Menu";
import DynamicModal from "./components/DynamicModal";
import ShippingLabelScanModal from "./components/ShippingLabelScanModal";

import {
  customerOrdersRowIndexState,
  customerOrdersState,
  permissionsState,
} from "../../atoms/atoms";
import useViewToggle from "../../hooks/useViewToggle";
import useCustomerOrders from "../../hooks/useCustomerOrders";
import useSearchFields from "../../hooks/useSearchFields";
import useModalManager from "../../hooks/useModalManager";
import { errorMsg, loadFilter, loadSearchTerm } from "../../common";

const CardViewControls = ({
  view,
  toggleView,
  handleSearch,
  searchFieldOptions,
  isMobile,
}) => {
  const theme = useTheme();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

  const handleMobileMenuClick = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.paper,
        py: 0.5,
        px: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: { xs: 1, sm: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            flexWrap: "wrap",
            ml: 1,
            gap: 1,
          }}
        >
          {!isMobile && (
            <Box mr={1}>
              <ViewToggleButtons
                view={view}
                onToggleView={toggleView}
                viewType={"card"}
              />
            </Box>
          )}
          <SearchOrdersInput
            table="customerOrders"
            handleSearch={handleSearch}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          maxWidth={"100%"}
        >
          <FilterMenu
            table="customerOrders"
            searchFieldOptions={searchFieldOptions}
            refetchOrders={handleSearch}
          />
          <Box ml={1}>
            <CardViewSettingsModal
              dataGridName="customerOrders"
              searchFields={searchFieldOptions}
            />
          </Box>
          {isMobile && (
            <IconButton size="small" onClick={handleMobileMenuClick}>
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      <Menu
        anchorEl={mobileMenuAnchorEl}
        open={Boolean(mobileMenuAnchorEl)}
        onClose={handleMobileMenuClose}
      >
        <MenuItem
          onClick={() => {
            toggleView("table");
            handleMobileMenuClose();
          }}
        >
          Switch to Table View
        </MenuItem>
      </Menu>
    </Box>
  );
};

const useOrderSelection = (customerOrders) => {
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const handleRowSelection = useCallback(
    (isNotEmpty, items) => {
      setSelectedRows(isNotEmpty ? items : null);
      setSelectedWarehouse(
        isNotEmpty && items.length > 0
          ? customerOrders.find((order) => order.id === items[0].id)?.warehouse
          : null
      );
    },
    [customerOrders, selectedRows?.length]
  );

  return {
    selectedRows,
    selectedWarehouse,
    handleRowSelection,
  };
};

const useContextMenu = () => {
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = useCallback((params) => {
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: params.position.x + 2,
            mouseY: params.position.y - 6,
            rowData: params.rowData,
          }
        : null
    );
  }, []);

  const handleContextMenuClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  const handleOpenInNewTab = useCallback(() => {
    if (contextMenu?.rowData?.wixordernumber) {
      window.open(`/order/${contextMenu.rowData.wixordernumber}`, "_blank");
    }
    handleContextMenuClose();
  }, [contextMenu]);

  return {
    contextMenu,
    handleContextMenu,
    handleContextMenuClose,
    handleOpenInNewTab,
  };
};

const CustomerOrders = () => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const permissions = useRecoilValue(permissionsState);
  const [customerOrders, setCustomerOrders] =
    useRecoilState(customerOrdersState);
  const setCustomerOrdersRowIndex = useSetRecoilState(
    customerOrdersRowIndexState
  );

  const {
    loadOrders,
    ordersLoading,
    ordersError,
    setFilterId,
    setSearchTerm,
    page,
    totalRows,
    hasFilter,
  } = useCustomerOrders();

  const [view, toggleView] = useViewToggle("customerOrders");
  const { openModal, closeModal, currentModal } = useModalManager();

  const { selectedRows, selectedWarehouse, handleRowSelection } =
    useOrderSelection(customerOrders);

  const gridRef = useRef(null);
  const loadingRef = useRef(false);

  const {
    contextMenu,
    handleContextMenu,
    handleContextMenuClose,
    handleOpenInNewTab,
  } = useContextMenu();

  const {
    searchFieldOptions,
    columns,
    searchFieldsLoading,
    searchFieldsError,
  } = useSearchFields(
    permissions?.["customerOrders"].columns,
    permissions?.id,
    "orderUnit"
  );

  const handleGridScroll = useCallback(
    async (params) => {
      if (loadingRef.current || ordersLoading) {
        return;
      }

      try {
        loadingRef.current = true;
        await loadOrders(page + 1);
      } finally {
        loadingRef.current = false;
      }
    },
    [ordersLoading, page, loadOrders]
  );

  // Initial load
  useEffect(() => {
    // Only load if we don't have any stored orders
    if (!customerOrders || customerOrders.length === 0) {
      loadOrders(0);
    }
  }, []);

  // Handle search/filter changes
  const handleSearch = useCallback(async () => {
    const newFilterId = loadFilter("customerOrders");
    const newSearchTerm = loadSearchTerm("customerOrders");

    setFilterId(newFilterId);
    setSearchTerm(newSearchTerm);
    await loadOrders(0);

    if (gridRef.current) {
      gridRef.current.scrollToIndexes({
        rowIndex: 0,
      });
    }
  }, [setFilterId, setSearchTerm, loadOrders]);

  // Modal state management
  const [isShippingLabelScanModalOpen, setIsShippingLabelScanModalOpen] =
    useState(false);
  const isScanning = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("modal") === "shippingLabelScan";
  }, [location.search]);

  useEffect(() => {
    if (isScanning) {
      setIsShippingLabelScanModalOpen(true);
    }
  }, [isScanning]);

  const handleCloseShippingLabelScanModal = useCallback(() => {
    setIsShippingLabelScanModalOpen(false);
    navigate(location.pathname, { replace: true });
  }, [navigate, location.pathname]);

  const onRowClick = useCallback(
    (params, e, openInNewTab) => {
      if (params?.row?.wixordernumber) {
        const rowIndex = params.api
          .getAllRowIds()
          .findIndex((rowID) => rowID === params.id);
        setCustomerOrdersRowIndex(rowIndex);

        if (openInNewTab) {
          window.open(`/order/${params.row.wixordernumber}`, "_blank");
        } else {
          navigate(`/order/${params.row.wixordernumber}`);
        }
        if (e?.button === 0) {
          e.preventDefault();
        }
      }
    },
    [navigate, setCustomerOrdersRowIndex]
  );

  const onActionSelect = useCallback(
    (action) => {
      if (!selectedRows) return;

      const selectedIDs = selectedRows.map((row) => row.id);
      const selectedOrders = customerOrders.filter((order) =>
        selectedIDs.includes(order.id)
      );
      openModal(action, { orders: selectedOrders });
    },
    [selectedRows, customerOrders, openModal]
  );

  const updateOrders = useCallback(
    (modalState, rsp = null) => {
      setCustomerOrders((prevState) =>
        prevState.map((order) => {
          const rows = Array.isArray(rsp) ? rsp : modalState.rows; // try to use fresh response rows, otherwise use modalState rows
          const updatedRow = rows.find((row) => row.id === order.id);
          return updatedRow ? { ...order, ...updatedRow } : order;
        })
      );
    },
    [setCustomerOrders]
  );

  const renderContent = useCallback(() => {
    if (
      searchFieldsLoading &&
      (view === "table" || (view === "card" && !ordersLoading))
    ) {
      return (
        <Box width={"40px"} m={"auto"}>
          <Loading />
        </Box>
      );
    }

    if (view === "table" && !searchFieldsLoading && columns) {
      return (
        <Box sx={{ height: `calc(100% - 50px)`, width: "100%" }}>
          {currentModal && (
            <DynamicModal
              onClose={closeModal}
              currentModal={currentModal}
              refetchOrders={updateOrders}
            />
          )}
          <DataGrid
            dataGridRef={gridRef}
            noBorder
            rowID="id"
            rows={customerOrders}
            rowHeight={75}
            storage={{
              pinned: "pinnedLeft",
              columns: "customerOrdersColumns",
              visibilityModel: "customerOrdersVisibility",
            }}
            dataGridName="customerOrders"
            searchFields={columns}
            loading={ordersLoading}
            hideFooter
            onRowClick={onRowClick}
            handleRowSelection={
              permissions.customerOrders.actions.bulkEdit && handleRowSelection
            }
            handleSearch={handleSearch}
            selectedWarehouse={selectedWarehouse}
            handleSelectionCheck={(params) =>
              params.row.shippingstatus === "DELIVERED" ||
              params.row["orderpaymentstatus"] === "NOT_PAID" ||
              params.row.canceled ||
              (selectedWarehouse && selectedWarehouse !== params.row.warehouse)
            }
            componentProps={{
              toolbar: {
                dataGridName: "customerOrders",
                tableName: "customerOrders",
                searchFields: searchFieldOptions,
                handleSearch,
                onActionSelect,
                selectedRows,
                ActionMenu,
                toggleView,
                view,
              },
            }}
            onRowsScrollEnd={hasFilter ? null : handleGridScroll}
            rowCount={Math.max(totalRows || 0, customerOrders.length)}
            scrollEndThreshold={200}
            sortingMode="server"
            filterMode="server"
            // onContextMenu={handleContextMenu}
          />
          <Menu
            open={contextMenu !== null}
            onClose={handleContextMenuClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem onClick={handleOpenInNewTab}>Open in New Tab</MenuItem>
          </Menu>
        </Box>
      );
    }

    if (view === "card") {
      if (ordersLoading) {
        return (
          <Box width={"40px"} m={"auto"}>
            <Loading />
          </Box>
        );
      }
      if (!ordersLoading && !searchFieldsLoading) {
        return (
          <Box
            sx={{
              height: { xs: "calc(100% - 100px)", sm: `calc(100% - 100px)` },
              width: "100%",
            }}
          >
            <CardView
              table="orderUnit"
              dataGridName="customerOrders"
              data={customerOrders}
              searchFields={columns}
              loading={ordersLoading}
              defaultColumnHeader={{
                fieldName: "shippingstatus",
                dataType: "string",
                label: "Shipping Status",
              }}
            />
          </Box>
        );
      }
    }

    return null;
  }, [
    view,
    searchFieldsLoading,
    ordersLoading,
    columns,
    customerOrders,
    currentModal,
    searchFieldOptions,
    selectedRows,
    selectedWarehouse,
    contextMenu,
    handleSearch,
    handleRowSelection,
    onRowClick,
    onActionSelect,
    handleContextMenuClose,
    handleOpenInNewTab,
    closeModal,
    updateOrders,
    permissions.customerOrders.actions.bulkEdit,
  ]);

  return (
    <MainContainer title="Customer Orders">
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {(ordersError || searchFieldsError) && (
          <Error
            sx={{ width: "100%", borderRadius: 0 }}
            errorMessage={errorMsg}
          />
        )}

        <ShippingLabelScanModal
          open={isShippingLabelScanModalOpen}
          handleClose={handleCloseShippingLabelScanModal}
        />

        {view === "card" && (
          <CardViewControls
            view={view}
            toggleView={toggleView}
            handleSearch={handleSearch}
            searchFieldOptions={searchFieldOptions}
            isMobile={isMobile}
          />
        )}

        {!isScanning && renderContent()}
      </Box>
    </MainContainer>
  );
};

export default CustomerOrders;
