import axios from "./axios";
import { API_ORDER_LINK_URL } from "../consts";

function addRole(permissions) {
  return axios
    .post(`${API_ORDER_LINK_URL}/permissions`, { permissions })
    .then((response) => response.data);
}

function deleteRole(roleID) {
  return axios
    .delete(`${API_ORDER_LINK_URL}/permissions?roleID=${roleID}`)
    .then((response) => response.data);
}

function getRole(roleID) {
  if (!roleID) {
    return Promise.reject(new Error("getRole: No role ID parameter."));
  }
  return axios
    .get(`${API_ORDER_LINK_URL}/permissions?roleID=${roleID}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error in getRole:", error);
      throw error; // Re-throw the error to be caught in PermissionGate
    });
}

function getAllRoles(roleID) {
  return axios
    .get(`${API_ORDER_LINK_URL}/permissions`, { roleID: roleID || "" })
    .then((response) => response.data);
}

function updateRole(roleID, permissions) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/permissions`, { roleID, permissions })
    .then((response) => response.data);
}

export { addRole, deleteRole, getRole, getAllRoles, updateRole };
