import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Divider, TextField, Typography } from "@mui/material";
import RecursiveAccordion from "./RecursiveAccordion";
import { usePermissionData } from "./usePermissionsData";
import IconSelector from "../../../../../../components/IconSelector";
import Loading from "../../../../../../components/Loading";

const Permissions = ({
  icon,
  setIcon,
  name,
  description,
  checkedState,
  setName,
  setDescription,
  setCheckedState,
  open,
  roleID,
  isEdit,
  nameError,
  isDuplicating,
}) => {
  const {
    searchFields,
    loading: dataLoading,
    error,
    roleData,
  } = usePermissionData(roleID, isEdit, open);
  const [initializing, setInitializing] = useState(true);

  const initializeCheckedState = useCallback((data) => {
    const initState = {};
    const initFieldState = (key, value) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (item && item.fieldName) {
            initState[`${key}.${item.fieldName}`] = false;
          }
        });
      } else if (typeof value === "object" && value !== null) {
        Object.keys(value).forEach((childKey) => {
          const newKey = key === "general" ? key : `${key}.${childKey}`;
          initFieldState(newKey, value[childKey]);
        });
      }
    };
    Object.entries(data).forEach(([key, value]) => {
      initFieldState(key, value);
    });
    return initState;
  }, []);

  const parseRoleData = useCallback((roleData, searchFields) => {
    let parsedState = {};
    const parseFields = (roleDataSection, searchFieldsSection, prefix = "") => {
      if (!searchFieldsSection) return;

      if (Array.isArray(searchFieldsSection)) {
        searchFieldsSection.forEach((field) => {
          if (field && field.fieldName) {
            const fullKey = `${prefix}${field.fieldName}`;
            parsedState[fullKey] =
              roleDataSection && roleDataSection[field.fieldName];
          }
        });
      } else if (typeof searchFieldsSection === "object") {
        Object.keys(searchFieldsSection).forEach((key) => {
          const newPrefix = prefix ? `${prefix}${key}.` : `${key}.`;
          parseFields(
            roleDataSection && roleDataSection[key],
            searchFieldsSection[key],
            newPrefix
          );
        });
      }
    };

    parseFields(roleData, searchFields);
    return parsedState;
  }, []);

  useEffect(() => {
    if (searchFields) {
      setInitializing(true);
      const baseState = initializeCheckedState(searchFields);

      if (isEdit && !isDuplicating && roleData) {
        const parsedRoleData = parseRoleData(roleData, searchFields);
        setCheckedState({ ...baseState, ...parsedRoleData });
        setIcon(roleData.icon);
        setName(roleData.name);
        setDescription(roleData.description);
      } else if (isDuplicating) {
        setCheckedState({ ...baseState, ...checkedState });
      } else {
        setCheckedState(baseState);
        setName("");
        setDescription("");
        setIcon(null);
      }

      setTimeout(() => setInitializing(false), 0);
    }
  }, [roleData, isEdit, isDuplicating, searchFields]);

  const handleCheck = useCallback(
    (key, checked) => {
      setCheckedState((prevState) => {
        const newState = { ...prevState };
        const updateState = (currentKey, newChecked) => {
          newState[currentKey] = newChecked;
          const updateChildren = (key, checked) => {
            const childData = key
              .split(".")
              .reduce(
                (acc, level) => (acc && acc[level] ? acc[level] : null),
                searchFields
              );
            if (Array.isArray(childData)) {
              childData.forEach((item) => {
                if (item && item.fieldName) {
                  let childKey = `${key}.${item.fieldName}`;
                  newState[childKey] = checked;
                  updateChildren(childKey, checked);
                }
              });
            } else if (typeof childData === "object" && childData !== null) {
              Object.keys(childData).forEach((subkey) => {
                let nestedChildKey = `${key}.${subkey}`;
                if (
                  typeof childData[subkey] === "object" &&
                  childData[subkey] !== null
                ) {
                  updateChildren(nestedChildKey, checked);
                } else {
                  newState[nestedChildKey] = checked;
                }
              });
            }
          };
          updateChildren(currentKey, newChecked);
          const updateParent = (key) => {
            const levels = key.split(".");
            levels.pop();
            if (levels.length === 0) return;
            const parentKey = levels.join(".");
            let allChecked = true;
            let someChecked = false;
            const checkDescendantsState = (currentKey) => {
              const currentData = currentKey
                .split(".")
                .reduce(
                  (acc, level) => (acc && acc[level] ? acc[level] : null),
                  searchFields
                );
              if (typeof currentData === "object" && currentData !== null) {
                Object.keys(currentData).forEach((childKey) => {
                  const fullChildKey = `${currentKey}.${childKey}`;
                  if (newState[fullChildKey] === true) {
                    someChecked = true;
                  } else if (
                    newState[fullChildKey] === false ||
                    newState[fullChildKey] === "indeterminate"
                  ) {
                    allChecked = false;
                  }
                  checkDescendantsState(fullChildKey);
                });
              }
            };
            checkDescendantsState(parentKey);
            newState[parentKey] = allChecked
              ? true
              : someChecked
              ? "indeterminate"
              : false;
            updateParent(parentKey);
          };
          updateParent(key);
        };
        updateState(key, checked);
        return newState;
      });
    },
    [searchFields]
  );

  if (dataLoading || initializing)
    return <Loading sx={{ width: "40px", m: "auto" }} />;
  if (error) return <Alert color="error">{error}</Alert>;

  if (!searchFields) {
    console.error("searchFields is undefined or null");
    return <Alert color="error">Error: Unable to load permission data</Alert>;
  }

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Typography fontWeight="bold" sx={{ mb: 2 }}>
        Details:
      </Typography>
      <TextField
        label="Name"
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        error={nameError}
        helperText={nameError && "Required field."}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={3}
        multiline
        sx={{ mb: 2 }}
      />
      <IconSelector
        selectedIcon={icon}
        handleSelectedIcon={(name) => setIcon(name)}
      />
      <Divider sx={{ my: 2 }} />
      <Typography fontWeight="bold" sx={{ mb: 2 }}>
        Table Settings:
      </Typography>
      <RecursiveAccordion
        data={searchFields}
        checkedState={checkedState}
        handleCheck={handleCheck}
      />
    </Box>
  );
};

export default React.memo(Permissions);
