import React, { useState } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import Box from "@mui/material/Box";

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;

  // Initialize CellMeasurerCache
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 50, // Adjust this value based on your average row height
  });

  return (
    <Box ref={ref} {...other}>
      <List
        height={250}
        width={
          document.getElementById("virtual-autocomplete").parentNode.offsetWidth
        }
        rowCount={itemCount}
        rowHeight={cache.rowHeight}
        deferredMeasurementCache={cache}
        overscanRowCount={5}
        rowRenderer={({ index, key, parent, style }) => (
          <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}
          >
            {({ measure, registerChild }) => (
              // Use a div instead of Box to avoid MUI Box's default width behavior
              <div
                ref={registerChild}
                onLoad={measure}
                style={{
                  ...style,
                  width: "100%", // Ensure full width
                  boxSizing: "border-box", // Include padding and border in the width calculation
                }}
              >
                {/* Wrap children in a Box if additional MUI styling is needed */}
                <Box
                  sx={{ p: "4px 10px", display: "flex", alignItems: "center" }}
                >
                  {children[index]}
                </Box>
              </div>
            )}
          </CellMeasurer>
        )}
        role={role}
      />
    </Box>
  );
});

export default function VirtualizedAutocomplete({
  options,
  handleValueChange,
  error,
  value,
}) {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      {options && (
        <Autocomplete
          id="virtual-autocomplete"
          value={value}
          multiple
          disableListWrap
          disableCloseOnSelect
          ListboxComponent={ListboxComponent}
          options={options}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderTags={(value, getTagProps) => (
            <Box sx={{ maxHeight: "200px", overflow: "auto", width: "100%" }}>
              {value.map((option, index) => (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                  onDelete={getTagProps({ index }).onDelete}
                  sx={{
                    backgroundColor: "lightgrey",
                    margin: "5px",
                  }}
                />
              ))}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Options"
              fullWidth
              error={error}
              helperText={error && "Please fill out this field."}
            />
          )}
          onChange={(e, newValue) => handleValueChange(newValue)}
          inputValue={inputValue}
          onBlur={() => setInputValue("")}
          onInputChange={(event, newInputValue, reason) => {
            // Only update input value if it's not from selecting an option
            if (reason !== "reset") {
              setInputValue(newInputValue);
            }
            // If it's from clearing the input or blurring, then allow clearing
            if (reason === "clear" || reason === "blur") {
              setInputValue("");
            }
          }}
        />
      )}
    </>
  );
}
