import { API_ORDER_LINK_URL } from "../consts";
import axios from "./axios";

function getColumnSettings(dataGridName) {
  return axios
    .get(
      `${API_ORDER_LINK_URL}/userSettings?type=columnSettings&dataGridName=${dataGridName}`
    )
    .then((response) => response.data);
}

function updateColumnSettings(dataGridName, settings) {
  return axios
    .patch(`${API_ORDER_LINK_URL}/userSettings`, {
      type: "columnSettings",
      settings: { ...settings, dataGridName },
    })
    .then((response) => response.data);
}

export { getColumnSettings, updateColumnSettings };
