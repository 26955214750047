import React, { useEffect, useMemo, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import Modal from "../../../components/Modal";
import { getInventoryOrderSummaryHistory } from "../../../api/History";
import Loading from "../../../components/Loading";
import { useDateFormat } from "../../../contexts/DateFormatContext";
import dayjs from "dayjs";
import { SearchFieldAutocomplete } from "../../../components/AutoComplete/AutoComplete";
import { DataGrid } from "@mui/x-data-grid";

const HistoryModal = ({ open, onClose, item, searchFields }) => {
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedColumns, setSelectedColumns] = useState(null);

  const { dateFormat } = useDateFormat();
  const specialDateFormat =
    dateFormat === "DD/MM/YYYY" ? "YYYY-MM-DD" : dateFormat;

  const formatDateTime = (date, secs = true) =>
    dayjs(date).format(`${specialDateFormat} HH:mm${secs ? ":ss" : ""}`);

  useEffect(() => {
    getInventoryOrderSummaryHistory(item.sid)
      .then((rsp) => {
        setHistory(rsp.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setHistory([]);
        setLoading(false);
      });
  }, [item.sid]);

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  const filteredHistory = useMemo(() => {
    if (!selectedColumns || selectedColumns.length === 0) {
      return null;
    }

    // Create a map of fieldName to displayName for formatting
    const fieldDisplayNames = new Map(
      searchFields.map((field) => [
        field.fieldName.toLowerCase(),
        field.displayName,
      ])
    );

    return history
      ?.map((historyItem) => {
        const filteredFields = historyItem.contextData.changedFieldsList.filter(
          (field) => {
            const fieldName = field.toLowerCase();
            return selectedColumns.some(
              (selectedField) =>
                selectedField.fieldName.toLowerCase() === fieldName
            );
          }
        );

        if (filteredFields.length === 0) return null;

        return {
          ...historyItem,
          contextData: {
            ...historyItem.contextData,
            changedFieldsList: filteredFields,
            changedFromData: Object.fromEntries(
              filteredFields.map((field) => [
                field,
                historyItem.contextData.changedFromData[field],
              ])
            ),
            changedToData: Object.fromEntries(
              filteredFields.map((field) => [
                field,
                historyItem.contextData.changedToData[field],
              ])
            ),
            // Add displayNames to the context data
            displayNames: Object.fromEntries(
              filteredFields.map((field) => [
                field,
                fieldDisplayNames.get(field.toLowerCase()) ||
                  formatFieldName(field),
              ])
            ),
          },
        };
      })
      .filter(Boolean);
  }, [history, selectedColumns, searchFields]);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date Changed",
      width: 200,
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Typography
            fontSize={13}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatDateTime(params.row.createdAt)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "changes",
      headerName: "Changes",
      flex: 1,
      renderCell: (params) => {
        const changes = params.row.contextData.changedFieldsList.map(
          (field) => ({
            field,
            displayName: params.row.contextData.displayNames[field],
            from: params.row.contextData.changedFromData[field],
            to: params.row.contextData.changedToData[field],
          })
        );

        return (
          <Box sx={{ py: 1, width: "100%" }}>
            {changes.map((change, index) => (
              <Box
                key={change.field}
                sx={{
                  mb: index !== changes.length - 1 ? 1 : 0,
                  display: "flex",
                  width: "100%",
                }}
              >
                <Typography
                  fontSize={13}
                  color="text.primary"
                  sx={{
                    width: 200,
                    flexShrink: 0,
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {change.displayName}:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Typography
                    fontSize={13}
                    color="text.secondary"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {typeof change.from === "number"
                      ? Number(change.from).toFixed(2)
                      : change.from}
                  </Typography>
                  <Typography fontSize={13} color="text.secondary">
                    →
                  </Typography>
                  <Typography
                    fontSize={13}
                    color="text.primary"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {typeof change.to === "number"
                      ? Number(change.to).toFixed(2)
                      : change.to}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        );
      },
    },
    {
      field: "user",
      headerName: "User",
      width: 150,
      renderCell: (params) => (
        <Typography
          fontSize={13}
          color={
            params.row.userType === "sys" ? "text.secondary" : "text.primary"
          }
        >
          {params.row.userType === "sys" ? "System" : params.row.userEmail}
        </Typography>
      ),
    },
  ];

  const updateSelectedColumns = (value) => {
    setSelectedColumns(value);
  };

  return (
    <Modal
      openModal={open}
      handleCloseModal={onClose}
      title={`History for item ${item.sid}`}
      actions={<></>}
      maxWidth={"lg"}
    >
      <Box>
        {loading && (
          <Box mx={"auto"} width={40}>
            <Loading />
          </Box>
        )}
        {!loading && searchFields && (
          <Box mb={2} width={"50%"}>
            <SearchFieldAutocomplete
              label={"Filter columns"}
              selectedField={selectedColumns}
              searchFields={searchFields?.slice(1) || []}
              onUpdate={updateSelectedColumns}
              multiple
            />
          </Box>
        )}
        {!loading && (!filteredHistory || filteredHistory.length === 0) && (
          <Paper
            elevation={1}
            sx={{
              width: "100%",
              py: 6,
              px: 4,
              textAlign: "center",
              borderRadius: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <Typography
                variant="h6"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                }}
              >
                {selectedColumns?.length > 0
                  ? "No Changes Found"
                  : "Select Fields to View History"}
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  maxWidth: 450,
                  lineHeight: 1.6,
                }}
              >
                {selectedColumns?.length > 0
                  ? "There are no recorded changes for the selected fields. Try selecting different fields to view their history."
                  : "Use the filter above to select which fields you want to track changes for. You can select multiple fields to view their history."}
              </Typography>
            </Box>
          </Paper>
        )}
        {!loading && filteredHistory?.length > 0 && (
          <DataGrid
            rows={filteredHistory}
            columns={columns}
            pagination
            rowsPerPage={10}
            getRowHeight={({ model }) => {
              // Get number of fields being displayed for this row
              const numberOfFields = model.contextData.changedFieldsList.length;
              // Calculate height based on number of fields (adjust the multiplier as needed)
              return 20 + numberOfFields * 30; // Base height + height per field
            }}
            autoHeight // Add this to make the grid adjust to content
            getEstimatedRowHeight={() => 100} // Provide an estimated height for virtual scrolling
            showCellVerticalBorder
            showColumnVerticalBorder
            sx={{
              "& .MuiDataGrid-cell": {
                padding: 1,
              },
              "& .MuiDataGrid-row": {
                alignItems: "start", // Align content to top
              },
            }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default HistoryModal;
