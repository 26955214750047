import pusher from "../pusherClient";
import { decrypt, decryptObject } from "../../../utils/encryption";

export const subscribeToNotificationChannel = (
  userID,
  location,
  messagesByConversation,
  setMessagesByConversation,
  setNotifications,
  activeConversationRef,
  activeConversation
) => {
  const channel = pusher.subscribe(`notifications-${userID}`);

  const handleNewMessage = async (data) => {
    const message = await decrypt(data.message);
    const conversationID = message.conversationID;

    if (!location.pathname.split("/").includes("order")) {
      if (conversationID in messagesByConversation) {
        setMessagesByConversation((prevState) => ({
          ...prevState,
          [conversationID]: [...prevState[conversationID], message],
        }));
      }
    } else {
      if (activeConversationRef.current === conversationID) return;
    }
    setNotifications((prevState) => [message, ...prevState]);
  };

  const handleDeleteMessage = async (data) => {
    const { messageID, conversationID } = await decrypt(data);

    if (conversationID in messagesByConversation)
      setMessagesByConversation((prevState) => ({
        ...prevState,
        [conversationID]: prevState[conversationID]?.filter(
          (message) => message.id !== messageID
        ),
      }));
    setNotifications((prevState) =>
      prevState.filter((message) => message.id !== messageID)
    );
  };

  channel.bind("new-message", handleNewMessage);
  channel.bind("delete-message", handleDeleteMessage);

  activeConversationRef.current = activeConversation;

  return () => {
    channel.unbind_all();
    pusher.unsubscribe(`notifications-${userID}`);
  };
};
