import React, { useEffect } from "react";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { modalState } from "../../../atoms/atoms";
import { useRecoilState } from "recoil";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";

const UpdateHoldStatus = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      orders: data.orders.map((order) => order.uid),
      holdStatus: "add",
      holdUntilDate: data.orders[0].holduntildate || dayjs(),
    });
  }, []);

  const handleChange = (event) => {
    setState((prevState) => ({ ...prevState, holdStatus: event.target.value }));
  };

  const handleDateChange = (date) => {
    setState((prevState) => ({ ...prevState, holdUntilDate: date }));
  };

  const orderCount = data.orders ? data.orders.length : 0;

  return (
    <Box sx={{ maxWidth: 600, mx: "auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 3,
        }}
      >
        <Box>
          <Typography color="text.secondary" variant="body1">
            Updating Hold Status for {orderCount} line item
            {orderCount !== 1 ? "s" : ""} selected
          </Typography>
        </Box>
      </Box>

      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: 2,
          border: "1px solid",
          borderColor: "divider",
          bgcolor: "background.paper",
          mb: 3,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            mb: 2,
            color: "text.secondary",
            fontWeight: 500,
          }}
        >
          Selected Orders:
        </Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
          {data.orders.map((order) => (
            <Chip
              key={order.uid}
              label={order.uid}
              size="small"
              sx={{
                bgcolor: "primary.50",
                color: "primary.main",
                "& .MuiChip-label": { px: 1 },
              }}
            />
          ))}
        </Stack>
      </Paper>

      {state.holdStatus && (
        <Paper
          elevation={0}
          sx={{
            p: 3,
            borderRadius: 2,
            border: "1px solid",
            borderColor: "divider",
            bgcolor: "background.paper",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              mb: 2,
              color: "text.secondary",
              fontWeight: 500,
            }}
          >
            Hold Options
          </Typography>

          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              row
              aria-label="hold-status"
              name="hold-status"
              value={state.holdStatus}
              onChange={handleChange}
              sx={{
                "& .MuiFormControlLabel-root": {
                  mr: 4,
                },
                "& .MuiRadio-root": {
                  color: "primary.main",
                },
              }}
            >
              <FormControlLabel
                value="add"
                control={<Radio />}
                label={
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Add/Update Hold Status
                  </Typography>
                }
              />
              <FormControlLabel
                value="remove"
                control={<Radio />}
                label={
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Remove Hold Status
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>

          {state.holdStatus === "add" && (
            <Box sx={{ mt: 3 }}>
              <DatePicker
                label="Hold Until Date"
                value={state.holdUntilDate}
                onChange={handleDateChange}
                required
                startingTimezone={data.orders[0]["shippingcountry_iso2"]}
              />
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default UpdateHoldStatus;
