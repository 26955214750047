import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";

import { useNavigate } from "react-router-dom";

import { useCookies } from "react-cookie";

import { notificationsState, sideBarOpenState } from "../../atoms/atoms";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { HeaderBar } from "./components/HeaderBar";
import { NavigationDrawer } from "./components/NavigationDrawer";
import { getMessagesByUserID } from "../../api/Messages";

export default function Main({ children, title }) {
  let navigate = useNavigate();

  const [cookies] = useCookies(["_auth"]);

  const open = useRecoilValue(sideBarOpenState);
  const setOpen = useSetRecoilState(sideBarOpenState);

  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const handleDrawerOpen = () => {
    setOpen(true);
    if (JSON.parse(localStorage.getItem("sideBarOpen")) !== true) {
      localStorage.setItem("sideBarOpen", JSON.stringify(true));
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
    if (JSON.parse(localStorage.getItem("sideBarOpen")) !== false) {
      localStorage.setItem("sideBarOpen", JSON.stringify(false));
    }
  };

  React.useEffect(() => {
    const getMessages = async () => {
      try {
        const messages = await getMessagesByUserID();
        if (messages) {
          const sortedMessages = messages
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((notification) => ({
              ...notification,
              starred: notification.starred || [],
            }));
          setNotifications(() => sortedMessages);
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (!notifications) getMessages();
  }, []);

  React.useEffect(() => {
    if (!cookies._auth && !window.location.pathname.startsWith("/login")) {
      navigate("/login");
    }
    if (open === null) {
      const savedState = JSON.parse(localStorage.getItem("sideBarOpen"));
      if (savedState || (savedState == null && window.matchMedia("(min-width: 768px)").matches)) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, []);

  return (
    <Box sx={{ display: "flex", p: 0 }}>
      <CssBaseline />
      <HeaderBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        title={title}
      />
      <NavigationDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <Box
        component="main"
        sx={{
          height: "calc(100vh)",
          flexGrow: 1,
          backgroundColor: "#f7f6f6",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <Toolbar sx={{ minHeight: "50px !important" }} />

        {children}
      </Box>
    </Box>
  );
}
