import { Box, Grid, Typography } from "@mui/material";
import { renderCellComponent } from "../../../../components/Datagrid/utils";
import React from "react";

export const OrderField = ({ field, product, longestLength, dateFormat }) => (
  <Grid
    container
    item
    xs={12}
    sm={12}
    sx={{ display: "flex", flexWrap: "wrap" }}
  >
    <Box
      sx={
        field.dataType === "boolean"
          ? { minWidth: "max-content", alignContent: "center" }
          : {}
      }
    >
      <Typography
        fontWeight="bold"
        sx={{
          mr: 1,
          minWidth: { xs: "auto", sm: `${longestLength}ch` },
          wordBreak: "break-word",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
        }}
      >
        {field.label}:
      </Typography>
    </Box>
    <Box
      sx={{
        minWidth: "50%",
        wordBreak: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
      }}
    >
      {renderCellComponent(
        field,
        field.fieldName,
        { value: product[field.fieldName], row: product },
        null,
        dateFormat
      )}
    </Box>
  </Grid>
);
