import React, { useCallback, useEffect, useRef, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { BrowserMultiFormatReader } from "@zxing/library";

import Modal from "../../../components/Modal";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import BarcodeIcon from "@mui/icons-material/QrCode2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ImageIcon from "@mui/icons-material/Image";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import RefreshIcon from "@mui/icons-material/Refresh";

const BarcodeScanner = ({ open, handleClose, onScan }) => {
  const [error, setError] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [isImageScanning, setIsImageScanning] = useState(false);
  const [scanMode, setScanMode] = useState("manual");
  const [cameraError, setCameraError] = useState(null);
  const html5QrCodeRef = useRef(null);
  const [barcodeBuffer, setBarcodeBuffer] = useState("");
  const barcodeInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const [cameraAvailable, setCameraAvailable] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isScanning && scanMode === "manual") {
      barcodeInputRef.current?.focus();
    }
  }, [isScanning, scanMode]);

  useEffect(() => {
    if (open) {
      setError("");
      setIsScanning(true);
      setScanMode("manual");
    }
  }, [open]);

  useEffect(() => {
    // Check if camera is available
    if (open) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            stream.getTracks().forEach((track) => track.stop());
            setCameraAvailable(true);
          })
          .catch((err) => {
            console.warn("Camera not available:", err);
            setCameraAvailable(false);
          });
      } else {
        setCameraAvailable(false);
      }
    }
  }, []);

  const resetScanner = useCallback(() => {
    if (html5QrCodeRef.current) {
      try {
        const state = html5QrCodeRef.current.getState();
        if (state !== Html5Qrcode.STATE_NOT_STARTED) {
          html5QrCodeRef.current
            .stop()
            .then(() => {
              html5QrCodeRef.current.clear();
              html5QrCodeRef.current = null;
            })
            .catch((err) => {
              console.warn("Error stopping scanner:", err);
              html5QrCodeRef.current = null;
            });
        } else {
          html5QrCodeRef.current.clear();
          html5QrCodeRef.current = null;
        }
      } catch (err) {
        console.warn("Error during scanner reset:", err);
        html5QrCodeRef.current = null;
      }
    }
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (!isScanning || scanMode !== "manual") return;

      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default to avoid any unwanted behavior
        handleBarcodeSubmit(barcodeBuffer);
      } else if (event.ctrlKey && event.key === "v") {
        navigator.clipboard.readText().then((clipText) => {
          handleBarcodeSubmit(clipText);
        });
      } else if (event.key.length === 1 && !event.ctrlKey) {
        setBarcodeBuffer((prev) => prev + event.key);
      }
    },
    [isScanning, scanMode, barcodeBuffer]
  );

  useEffect(() => {
    if (open && isScanning && scanMode === "manual") {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, isScanning, scanMode, handleKeyDown]);

  useEffect(() => {
    if (open) {
      if (isScanning && scanMode === "camera" && cameraAvailable) {
        resetScanner();

        const html5QrCode = new Html5Qrcode("reader");
        html5QrCodeRef.current = html5QrCode;

        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
          console.log(`Camera scan success: ${decodedText}`);
          handleBarcodeSubmit(decodedText);
        };

        const qrCodeErrorCallback = (errorMessage, error) => {
          // Don't set error for every frame without a barcode
          if (errorMessage !== "No barcode or QR code detected.") {
            console.warn("QR Code scanning error:", errorMessage, error);
          }
        };

        const config = {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          aspectRatio: 1.0,
          formatsToSupport: [
            Html5Qrcode.BARCODE_FORMAT_CODE_128,
            Html5Qrcode.BARCODE_FORMAT_CODE_39,
            Html5Qrcode.BARCODE_FORMAT_EAN_13,
            Html5Qrcode.BARCODE_FORMAT_EAN_8,
            Html5Qrcode.BARCODE_FORMAT_UPC_A,
            Html5Qrcode.BARCODE_FORMAT_UPC_E,
            Html5Qrcode.BARCODE_FORMAT_QR_CODE,
            Html5Qrcode.PDF_417,
          ],
        };

        html5QrCode
          .start(
            { facingMode: "environment" },
            config,
            qrCodeSuccessCallback,
            qrCodeErrorCallback
          )
          .then(() => {
            console.log("Camera started successfully");
          })
          .catch((err) => {
            console.error("Error starting camera:", err);
            setCameraError(
              "Failed to access the camera. Please check your camera permissions or try manual input."
            );
            setScanMode("manual");
          });
      }

      return () => {
        resetScanner();
      };
    }
  }, [isScanning, scanMode, resetScanner, cameraAvailable]);

  const handleScanStart = () => {
    setBarcodeBuffer("");
    setError("");
    setCameraError(null);
    setIsScanning(true);
  };

  const handleBarcodeSubmit = async (barcode) => {
    if (barcode.length === 0) {
      setError("Invalid barcode scanned. Please try again.");
      return;
    }
    try {
      resetScanner();
      await onScan(barcode);
      handleClose();
    } catch (err) {
      console.error("Error in onScan callback:", err);
      setError(
        "An error occurred while processing the barcode. Please try again."
      );
    } finally {
      setBarcodeBuffer("");
    }
  };

  const handleScanModeChange = (event, newMode) => {
    if (newMode !== null) {
      setError("");
      setIsScanning(false);
      resetScanner();
      setScanMode(newMode);
      setCameraError(null);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsImageScanning(true);
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const imageData = e.target.result;
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, image.width, image.height);

          const codeReader = new BrowserMultiFormatReader();
          codeReader
            .decodeFromImage(undefined, imageData)
            .then((result) => {
              console.log("Barcode found", result.text);
              handleBarcodeSubmit(result.text);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "No barcode found in the image. Please try a different image or input the barcode manually."
              );
            })
            .finally(() => {
              setIsImageScanning(false);
              codeReader.reset();
            });
        };
        image.src = imageData;
      };
      fileReader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      title="Scan Barcode"
      openModal={open}
      handleCloseModal={handleClose}
      errorMsg={error}
      actions={<></>}
    >
      <Box display={"flex"} flexDirection={"column"} gap={3} mt={error ? 1 : 0}>
        <Box display="flex" justifyContent="center" width="100%" mb={1}>
          <ToggleButtonGroup
            value={scanMode}
            exclusive
            onChange={handleScanModeChange}
            aria-label="scan mode"
            size={isMobile ? "small" : "medium"}
          >
            <ToggleButton value="manual" aria-label="manual input">
              <BarcodeIcon />
              <Box ml={1}>Manual</Box>
            </ToggleButton>
            <ToggleButton value="camera" aria-label="use camera">
              <CameraAltIcon />
              <Box ml={1}>Camera</Box>
            </ToggleButton>
            <ToggleButton value="image" aria-label="upload image">
              <ImageIcon />
              <Box ml={1}>Image</Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {cameraError && (
          <Alert severity="warning" onClose={() => setCameraError(null)}>
            {cameraError}
          </Alert>
        )}

        {isImageScanning ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="220px"
          >
            <CircularProgress sx={{ mb: 3 }} />
          </Box>
        ) : scanMode === "camera" && isScanning ? (
          <>
            {cameraAvailable ? (
              <Box
                id="reader"
                width="100%"
                height="300px"
                style={{ position: "relative" }}
              >
                <Typography
                  variant="body2"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    padding: "5px",
                  }}
                >
                  Point camera at barcode
                </Typography>
              </Box>
            ) : (
              <Box>
                <Alert severity="warning" onClose={() => setCameraError(null)}>
                  Camera isn't available. Please try manual input or image
                  upload.
                </Alert>
              </Box>
            )}
          </>
        ) : scanMode === "image" ? (
          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                component="span"
                fullWidth
                sx={{
                  py: 3,
                  backgroundColor: isScanning ? "teal" : undefined,
                }}
                startIcon={<ImageIcon />}
              >
                Choose Image
              </Button>
            </label>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={2}>
            {isScanning ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  mx={1}
                  width={"100%"}
                >
                  <Paper
                    sx={{
                      border: 0,
                      flexGrow: 1,
                    }}
                  >
                    <Typography variant="h6" color="secondary">
                      Awaiting input...
                    </Typography>
                  </Paper>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<RefreshIcon />}
                    onClick={handleScanStart}
                  >
                    Restart Scan
                  </Button>
                </Box>

                <TextField
                  fullWidth
                  variant="outlined"
                  label="Barcode"
                  value={barcodeBuffer}
                  autoFocus
                  disabled
                />
              </Box>
            ) : (
              <Button
                variant="contained"
                startIcon={
                  scanMode === "camera" ? <CameraAltIcon /> : <BarcodeIcon />
                }
                fullWidth
                sx={{
                  py: 3,
                  backgroundColor: isScanning ? "teal" : undefined,
                }}
                onClick={handleScanStart}
              >
                Start {scanMode === "camera" ? "Camera" : "Manual"} Scan
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default BarcodeScanner;
