import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import Table from "../../../components/Table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { inventoryWarehouses } from "../../../common";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";
import { getProductsByCountryAndWarehouse } from "../../../api/Products";

const CreateAuditCorrection = () => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);
  const [loading, setLoading] = useState(false);
  const selectedWarehouse = localStorage.getItem("selectedWarehouse");

  useEffect(() => {
    setState({
      warehouse: "",
      notes: "",
      dateOrdered: dayjs(),
      rows: [
        {
          id: 0,
          inventoryItem: "",
          pid: "",
          quantityOrdered: 1,
          notes: "",
        },
      ],
      products: null,
    });
  }, [setState]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (state.warehouse) {
        setLoading(true);
        try {
          const countryCode = state.warehouse.slice(0, 2);
          const { countryProducts } = await getProductsByCountryAndWarehouse(
            countryCode,
            state.warehouse
          );
          setState((prevState) => ({
            ...prevState,
            products: countryProducts,
          }));
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchProducts();
  }, [state.warehouse, setState]);

  const handleTableChange = (value, field, id = null) => {
    if (id === null) {
      setState((prevState) => ({ ...prevState, [field]: value }));
      return;
    }

    setState((prevState) => {
      const { products, rows } = prevState;

      const updatedRows = rows.map((row) => {
        if (row.id !== id) return row;

        if (field === "pid") {
          if (!value) {
            return {
              ...row,
              pid: "",
              inventoryItem: "",
            };
          }

          const selectedProduct = products.find((p) => p.pid === value.pid);
          if (selectedProduct) {
            return {
              ...row,
              pid: selectedProduct.pid,
              inventoryItem: selectedProduct.description,
            };
          }
        }

        return { ...row, [field]: value };
      });

      return { ...prevState, rows: updatedRows };
    });
  };

  const handleChange = (item, name) => {
    setState((prevState) => ({ ...prevState, [name]: item }));
  };

  const handleDeleteOrder = (rowID) => {
    setState((prevState) => ({
      ...prevState,
      rows: prevState.rows
        .filter((row) => row.id !== rowID)
        .map((row, index) => ({ ...row, id: index })),
    }));
  };

  const handleAdd = () => {
    setState((prevState) => ({
      ...prevState,
      rows: [
        ...prevState.rows,
        {
          id: prevState.rows.length,
          inventoryItem: "",
          pid: "",
          quantityOrdered: 1,
          notes: "",
        },
      ],
    }));
  };

  const handleDateChange = (value) => {
    setState((prevState) => ({ ...prevState, dateOrdered: value }));
  };

  const columns = [
    {
      field: "pid",
      headerName: "Product",
      width: 400,
      editable: true,
      renderEditCell: ({ row }) => {
        const options =
          state.products?.map((product) => ({
            pid: product.pid,
            description: product.description,
          })) || [];

        const currentValue =
          options.find((option) => option.pid === row.pid) || null;

        return (
          <Autocomplete
            fullWidth
            options={options}
            getOptionLabel={(option) => {
              if (!option) return "";
              return `${option.description} (${option.pid})`;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Search by Name or PID"
                onClick={(event) => event.stopPropagation()}
              />
            )}
            value={currentValue}
            onChange={(_, newValue) => {
              handleTableChange(newValue, "pid", row.id);
            }}
            isOptionEqualToValue={(option, value) => {
              if (!option || !value) return false;
              return option.pid === value.pid;
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <Box>
                  <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {option.description}
                  </Typography>
                  <Typography
                    component="span"
                    color="text.secondary"
                    sx={{ ml: 1 }}
                  >
                    ({option.pid})
                  </Typography>
                </Box>
              </li>
            )}
            filterOptions={(options, { inputValue }) => {
              const searchTerm = inputValue.toLowerCase();
              return options.filter(
                (option) =>
                  option.description.toLowerCase().includes(searchTerm) ||
                  option.pid.toLowerCase().includes(searchTerm)
              );
            }}
          />
        );
      },
    },
    {
      field: "quantityOrdered",
      headerName: "Quantity Ordered",
      width: 100,
      editable: true,
      type: "number",
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => handleTableChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box>
        <Typography variant="h6" gutterBottom mb={2}>
          Audit Correction Details
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Autocomplete
            sx={{ width: { xs: "100%", sm: 300 } }}
            value={state.warehouse}
            onChange={(event, newValue) => handleChange(newValue, "warehouse")}
            options={inventoryWarehouses}
            renderInput={(params) => (
              <TextField label="Warehouse" fullWidth {...params} />
            )}
          />
          <TextField
            multiline
            rows={3}
            label="Notes"
            value={state.notes}
            onChange={(e) => handleChange(e.target.value, "notes")}
            fullWidth
          />
          <DatePicker
            label="Date Ordered"
            value={state.dateOrdered}
            onChange={handleDateChange}
            required
            startingTimezone={state.warehouse?.slice(0, 2)}
          />
        </Box>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box>
        <Typography variant="h6" gutterBottom>
          Product List
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : state.products ? (
          <Table
            rows={state.rows}
            columns={columns}
            onCellChange={handleTableChange}
            handleAdd={handleAdd}
            handleDelete={handleDeleteOrder}
          />
        ) : (
          <Typography color="textSecondary">
            Please select a warehouse to view products.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CreateAuditCorrection;
