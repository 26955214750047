import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Chip, TextField, Typography } from "@mui/material";
import Modal from "../../../components/Modal";

const AddProductModal = ({
  open,
  handleClose,
  warehouseProducts,
  addProduct,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (open) {
      setDescription("");
      setError("");
      setSelectedProducts([]);
    }
  }, [open]);

  const handleSubmit = () => {
    if (selectedProducts.length > 0) {
      if (
        selectedProducts
          .map((prod) => prod.pid)
          .includes("CUST-1-CUSTOM-0-0") &&
        !description
      )
        return setError(
          "Please provide a description for the custom product. "
        );
      selectedProducts.forEach((product) =>
        addProduct(
          product.pid === "CUST-1-CUSTOM-0-0"
            ? {
                ...product,
                id: product.pid,
                description: description,
                inventoryitem: description,
                supplier: "",
                tradefinanceorder: false,
                notes: "",
              }
            : product
        )
      );
      setSelectedProducts([]);
      handleClose();
    }
  };

  const products = [
    ...warehouseProducts,
    { inventoryitem: "Custom Product", pid: "CUST-1-CUSTOM-0-0" },
  ];

  return (
    <Modal
      title="Add New Products"
      openModal={open}
      handleCloseModal={handleClose}
      handleSubmit={handleSubmit}
      errorMsg={error}
    >
      <Box sx={{ width: "100%", mt: error ? 1 : 2 }}>
        <Autocomplete
          multiple
          options={products}
          getOptionLabel={(option) => `${option.inventoryitem} (${option.pid})`}
          renderInput={(params) => (
            <TextField {...params} label="Search products" />
          )}
          onChange={(event, newValue) => {
            setSelectedProducts(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.pid === value.pid}
          filterOptions={(options, { inputValue }) => {
            const searchTerm = inputValue.toLowerCase();
            return options.filter(
              (option) =>
                option.inventoryitem.toLowerCase().includes(searchTerm) ||
                option.pid.toLowerCase().includes(searchTerm)
            );
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div>
                <strong>{option.inventoryitem}</strong>
                <Typography variant="body2" color="text.secondary">
                  {option.pid}
                </Typography>
              </div>
            </li>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={`${option.inventoryitem} (${option.pid})`}
                {...getTagProps({ index })}
              />
            ))
          }
        />
        {selectedProducts
          .map((prod) => prod.pid)
          .includes("CUST-1-CUSTOM-0-0") && (
          <TextField
            sx={{ mt: 3 }}
            label={"Description"}
            rows={2}
            multiline
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        )}
      </Box>
    </Modal>
  );
};

export default AddProductModal;
